import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const Label = (props) => {
    const { text } = props;
    return <Form.Label> {text} </Form.Label>
}

Label.propTypes = {
    text: PropTypes.string.isRequired
}
export default Label;