import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const Input = (props) => {
    const { type, id, name, className, required, placeholder, value, onChange } = props;
    return <Form.Control 
                type={type}
                id={id} 
                name={name}
                className={className}                
                required={required}
                placeholder={placeholder}
                value={value}
                onChange={onChange} />
}

Input.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired, 
    name: PropTypes.string.isRequired, 
    className: PropTypes.string, 
    required: PropTypes.string, 
    placeholder: PropTypes.string,
    value: PropTypes.any,
    /**Functions */
    onChange: PropTypes.func
}

export default Input;