import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import PlayerMenu from "../../Organisms/PlayerMenu";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import AppHeader from "../../Organisms/AppHeader";
import axios from "axios";
import { EnvConfig } from "../../../Lib/EnvConfig";
import Swal from "sweetalert2";
import { create } from "../../../Features/Profile/profileSlice";

const Dashboard = () => {    
    const profile = useSelector((state)=>state.profile.value.payload),
        [isLoading,setIsLoading] = useState(false),
        dispatch = useDispatch();
    const checkTokenSession = async() => {
        const token = sessionStorage.getItem('BTYAPP-KEY-TOKEN');
        if(token!==undefined&&token!==null&&token.length>0){
            setIsLoading(true);
            try{
                const get = await axios.get(`${EnvConfig.host}v1/profile/${token}`),
                    data = await get.data;
                if(data.status){
                    const reduxObject = {
                        ...data.member,
                        positiveStats: data.positiveStats,
                        negativeStats: data.negativeStats,
                        neutralStats: data.neutralStats,
                        hits: data.hits,
                        turns: data.turns,
                        avg: data.avg,
                        teams: data.teams,
                        parentAccounts: data.parentAccounts,
                        token: data.member.token
                    }
                    dispatch(create(reduxObject));
                }
                setIsLoading(false);
            } catch(error){
                setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        }
    }
    useEffect(()=>{
        checkTokenSession();
    // eslint-disable-next-line
    },[]);
    return (        
        <>
            <AppHeader />
            <Container className="eight-height vertical-over">                
                {
                    (profile===undefined||profile===null||Object.keys(profile).length<1)?                    
                        <Row>
                            <Col>
                                Hemos detectado que refrescaste la app de forma no debida, Por favor <NavLink to="/login">Presiona aqui!</NavLink>
                            </Col>
                        </Row>
                    :
                    (isLoading)?
                        <Row>
                            <Col>
                                <Spinner animation="border" role="status" /> Cargando información...
                            </Col>
                        </Row>
                    :
                    <>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white mb-3">
                                <Card.Header>Informaciones Generales</Card.Header>
                                <Card.Body>
                                    <Card.Title className="bold text-capitalize">{profile.m_name}</Card.Title>
                                    <Card.Text className="text-muted">
                                        Estas son las informaciones que tenemos relacionadas a tu perfil
                                    </Card.Text>
                                    <Table responsive borderless>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Jugador</strong><br />
                                                    {profile.m_name}
                                                </td>
                                                <td>
                                                    <strong>Cuenta</strong><br />
                                                    {profile.email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Teléfono</strong><br />
                                                    {profile.phone}
                                                </td>
                                                <td>
                                                    <strong>Genero</strong><br />
                                                    {profile.gender}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Edad</strong><br />
                                                    {profile.age}
                                                </td>
                                                <td>
                                                    <strong>Rol</strong><br />
                                                    {profile.rol}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Tipo de cuenta</strong><br />
                                                    {profile.m_type}
                                                </td>
                                                <td>
                                                    <strong>Creado por</strong><br />
                                                    {profile.created_by}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Creado En</strong><br />
                                                    {profile.created_at}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white mb-3">
                                <Card.Header>Estadísticas en vivo</Card.Header>
                                <Card.Body>
                                    <Card.Title className="text-primary bold">AVG. {profile.avg}</Card.Title>
                                    <Card.Text className="text-muted">
                                        Estadísticas General del Jugador
                                    </Card.Text>
                                    <Table responsive borderless>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} className="text-success bold">+Positivas</td>
                                            </tr>
                                            {profile.positiveStats.map((element, idx) => {
                                                return (<tr key={`positive-${idx}`}>
                                                    <td style={{width: '50%'}} className="text-align-right"><strong>{element.metric}</strong></td>
                                                    <td style={{width: '50%'}} className="text-align-left">{element.quantity}</td>
                                                </tr>);
                                            })}                                            
                                            <tr>
                                                <td colSpan={2} className="text-secondary bold">=Neutral</td>
                                            </tr>
                                            {profile.neutralStats.map((element, idx) => {
                                                return (<tr key={`positive-${idx}`}>
                                                    <td style={{width: '50%'}} className="text-align-right"><strong>{element.metric}</strong></td>
                                                    <td style={{width: '50%'}} className="text-align-left">{element.quantity}</td>
                                                </tr>);
                                            })}
                                            <tr>
                                                <td colSpan={2} className="text-danger bold">-Negativas</td>
                                            </tr>
                                            {profile.negativeStats.map((element, idx) => {
                                                return (<tr key={`negative-${idx}`}>
                                                    <td style={{width: '50%'}} className="text-align-right"><strong>{element.metric}</strong></td>
                                                    <td style={{width: '50%'}} className="text-align-left">{element.quantity}</td>
                                                </tr>);
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white">
                                <Card.Header>Mis Equipos</Card.Header>
                                <Card.Body>
                                    <Card.Title className="bold">Equipos del miembro</Card.Title>
                                    <Card.Text className="text-muted">
                                        {
                                            profile.teams?
                                                profile.teams.map((el,ix)=>{
                                                    return (
                                                        <Row key={`p-${ix}`} className="mb-3">
                                                            <Col>
                                                                <span className="bold font-size-24 text-primary">{el.team}</span><br />
                                                                <span>Deporte. {el.sport}</span><br />
                                                                <span>Tipo. {el.t_team}</span><br />
                                                                <span>Ganados. {el.wins}</span><br />
                                                                <span>Perdidos. {el.loses}</span>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            :null
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    </>
                }                
            </Container>
            <Container className="ten-height d-flex align-items-end" fluid>
                {/* Menu Footer */}
                <PlayerMenu menu="resume" />
            </Container>
        </>        
    )
}

export default Dashboard;