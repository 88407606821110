import { createSlice } from '@reduxjs/toolkit'

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    value: {},
  },
  reducers: {
    create: (state, action) => {        
        const payload =  action.payload;
        state.value = {
            ...state.value,
            payload
        }
    },
    reset: (state) => {
      state.value = null
    },
  },
})

// Action creators are generated for each case reducer function
export const { create, reset } = profileSlice.actions

export default profileSlice.reducer