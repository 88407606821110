import React from "react";
import { Container, Nav, /*NavDropdown,*/ Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AppHeader = () => {
    const navigate = useNavigate();
    const redirectToDashboard = (e) => {
        e.preventDefault();
        navigate("/dashboard");
    }
    const goTo = (e) => {
        e.preventDefault();
        const href = e.currentTarget.getAttribute("href");
        navigate(href);
    }
    return (        
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="mb-2">
            <Container>
            <Navbar.Brand href="#" onClick={redirectToDashboard}>BtyApp</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/game" onClick={goTo}>Juegos</Nav.Link>
                    <Nav.Link href="/game/reported" onClick={goTo}>Juegos del día</Nav.Link>
                    <Nav.Link href="/live/stats" onClick={goTo}>Estadísticas en vivo</Nav.Link>
                    {/* <Nav.Link href="#pricing">Pricing</Nav.Link>
                    <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                        Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                        Separated link
                        </NavDropdown.Item>
                    </NavDropdown>
                    </Nav>
                    <Nav>
                    <Nav.Link href="#deets">More deets</Nav.Link>
                    <Nav.Link eventKey={2} href="#memes">
                        Dank memes
                    </Nav.Link> */}
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AppHeader;