import React from "react";
import { Stack, Spinner } from "react-bootstrap";
import CustomButton from "../../Atoms/CustomButton";
import PropTypes from "prop-types";
const StackLoginBtns = (props) => {
    const { logIn, isLoading } = props;
    return (
        <Stack gap={2} className="col-md-5 mx-auto mb-3">
            {
                isLoading?
                    <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                :
                <>
                    <CustomButton btn="primary" text="Entrar Aplicación" onClick={logIn} />
                    {/* <CustomButton btn="outline-secondary" text="Olvide La Contraseña" /> */}
                </>
            }            
        </Stack>
    )
}
StackLoginBtns.propTypes = {
    logIn: PropTypes.func,
    isLoading: PropTypes.bool
}
export default StackLoginBtns;