import { Route } from "react-router-dom";
import Login from "../../Components/Pages/Login";
import Dashboard from "../../Components/Pages/Dashboard";
import Members from "../../Components/Pages/Members";
import Team from "../../Components/Pages/Team";
import Game from "../../Components/Pages/Game";
import GameReported from "../../Components/Pages/GameReported";
import TeamGames from "../../Components/Pages/TeamGames";
import TeamPlayers from "../../Components/Pages/TeamPlayers";
import LiveStats from "../../Components/Pages/LiveStats";
import PlayerStats from "../../Components/Pages/PlayerStats";
import Profile from "../../Components/Pages/Profile";

export const RoutesLst = [
    <Route key={0} exact path="/" element={<Login />} />,
    <Route key={1} exact path="/login" element={<Login />} />,
    <Route key={2} exact path="/dashboard" element={<Dashboard />} />,
    <Route key={3} exact path="/members" element={<Members />} />,
    <Route key={4} exact path="/team" element={<Team />} />,
    <Route key={5} exact path="/game" element={<Game />} />,
    <Route key={6} exact path="/game/reported" element={<GameReported />} />,
    <Route key={7} exact path="/team/game/:idGame" element={<TeamGames />} />,
    <Route key={8} exact path="/team/players/:idGame/:idTeam" element={<TeamPlayers />} />,
    <Route key={9} exact path="/live/stats" element={<LiveStats />} />,
    <Route key={10} exact path="/player/live/stats/:idMember" element={<PlayerStats />} />,
    <Route key={11} exact path="/profile" element={<Profile />} />,
    <Route key={12} exact path="/team/players/:idGame/:idTeam/:noInning" element={<TeamPlayers />} />,
];