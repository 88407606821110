import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import AppHeader from "../../Organisms/AppHeader";
import PlayerMenu from "../../Organisms/PlayerMenu";
import FormInputGroup from "../../Molecules/FormInputGroup";
import FormSelectGroup from "../../Molecules/FormSelectGroup";
import Swal from "sweetalert2";
import { EnvConfig } from "../../../Lib/EnvConfig";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomButton from "../../Atoms/CustomButton";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
const memberData = {
    id: 0,
    name: "",
    email: "",
    phone: "",
    gender: "",
    age: 0,
    rol: 0,
    password: "",
    mType: "",
    teamId: 0,
    adminControl: 'f',
    qtyTeams: 0,
    qtyMembers: 0
}
const adminControlContent = [
    {
        value: 'f',
        text: 'NO'
    },
    {
        value: 't',
        text: 'SI'
    }
];
const Members = () => {
    const [member,setMember] = useState(memberData),
        [rolLst,setRolLst] = useState([]),
        [isLoadingData,setIsLoadingData] = useState(false),
        profile = useSelector((state)=>state.profile.value.payload),
        [isLoading,setIsLoading] = useState(false),
        [memberLst,setMemberLst] = useState([]),
        [createForm,setCreateForm] = useState(false);

    const getMembers = async() => {
        try{
            const get = await axios.get(`${EnvConfig.host}v1/members/form/by/parent/${profile.id}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data; 
            setMemberLst(data);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const handleValues = (input) => {
        const key = input.target.id,
            value = input.target.value;
        setMember({
            ...member,
            [key]: value
        })
    }
    const generalInfo = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/generals`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            let list = [],
                roles = data.roles;
                list.push({value: 0, text: "Rol del miembro"})
            for(let counter = 0; counter < roles.length; counter++){
                list.push({
                    value: roles[counter].id,
                    text: roles[counter].description
                });
            }
            setRolLst(list);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    useEffect(()=>{
        if(profile!==undefined&&profile!==null&&Object.keys(profile).length>0){
            getMembers();
            generalInfo();
        }        
    // eslint-disable-next-line
    }, []);
    const createMember = async() => {
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/members/save`, member, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: data.message
            });
            setMember(memberData);
            setIsLoading(false);
            setCreateForm(false);
            getMembers();
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const updateMember = async(element) => {
        const id = element.currentTarget.getAttribute("id");
        if(id){
            setIsLoadingData(true);
            try{
                const get = await axios.get(`${EnvConfig.host}v1/members/${id}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                    data = await get.data; 

                setMember({                    
                    id: parseInt(data.id),
                    name: data.m_name,
                    email: data.email,
                    phone: data.phone,
                    gender: data.gender,
                    age: data.age,
                    rol: parseInt(data.id_rol),
                    mType: data.m_type,
                    adminControl: data.admin_control,
                    qtyTeams: data.qty_teams,
                    qtyMembers: data.qty_members
                });
                setIsLoadingData(false);
                setCreateForm(true);
            } catch(error){
                setIsLoadingData(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        }
    }
    const clearMember = () => {
        setMember(memberData);
    }
    const removeMember = async() => {
        if(member.id){
            setIsLoadingData(true);
            try{
                const post = await axios.post(`${EnvConfig.host}v1/member/remove`, { id: member.id }, { headers: { Authorization: `Bearer ${profile.token}`}}),
                    data = await post.data;
                if(!data.status){
                    Swal.fire({
                        icon: "warning",
                        title: "Advertencia",
                        text: data.message,
                        confirmButtonColor: '#f00'
                    });
                    setIsLoading(false);
                    return;
                }
                Swal.fire({
                    icon: "success",
                    title: "Operación Exitosa",
                    text: data.message
                });
                setIsLoadingData(false);
                setMember(memberData);
                setCreateForm(false);
                getMembers();
            } catch(error){
                setIsLoadingData(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        }
    }
    return (
    <>
        <AppHeader />
        <Container className="eight-height vertical-over">            
            {
                isLoadingData?
                    <Row>
                        <Col className="text-align-center">
                            <p><Spinner animation="border" role="status" /> Cargando información...</p>
                        </Col>
                    </Row>
                :null
            }
            {
                (profile===undefined||profile===null||Object.keys(profile).length<1)?
                    <Row>
                        <Col>
                            <p>Hemos detectado que refrescaste la app de forma no debida, Por favor <NavLink to="/login">Presiona aqui!</NavLink></p>
                        </Col>
                    </Row>
                :
                (profile.m_type!=='admin')?
                    <Row>
                        <Col>
                            <p>No tienes permisos para visualizar estas informaciones... Contacta al administrador</p>
                        </Col>
                    </Row>
                :
            <>
            {
                memberLst.length?
                <Row>
                    <Col className="text-align-center font-size-24 bold">Mis Miembros</Col>
                </Row>
                :null
            }
            {
                memberLst.length&&!createForm?
                <>
                    {
                    memberLst.map((elm,idx)=>{
                        return (
                            <Row key={`member-team-${idx}`}>
                                <Col>
                                    <Card id={elm.id} key={idx} className="mb-3 cursor" onClick={updateMember}>
                                        <Card.Body>
                                            <Card.Subtitle className="text-align-right font-size-24 bold text-primary"><FontAwesomeIcon icon={faEdit} /></Card.Subtitle>
                                            <Card.Title>{elm.player}</Card.Title>                                            
                                            <Card.Subtitle className="mb-2 text-muted">Teléfono. {elm.phone}</Card.Subtitle>
                                            <Card.Subtitle className="mb-2 text-muted">Correo Electrónico. {elm.email}</Card.Subtitle>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )
                    })
                    }
                </>
                :null
            }
            {
                createForm?
                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white mb-3">
                                <Card.Header>{member.id>0?"Actualizar Miembro":"Nuevo Miembro"}(Jugador)</Card.Header>
                                <Card.Body>
                                    <Card.Title className="bold text-capitalize">Miembro</Card.Title>
                                    <Card.Text className="text-muted">
                                        Tienes acceso a registrar los miembros que desees para tu equipo
                                    </Card.Text>
                                    <FormInputGroup 
                                        type="text" 
                                        id="name" 
                                        name="name" 
                                        label="Nombre" 
                                        placeholder="Ingrese el nombre completo"
                                        value={member.name}
                                        onChange={handleValues}
                                        />
                                    <FormInputGroup 
                                        type="email" 
                                        id="email" 
                                        name="email" 
                                        label="Correo Electrónico" 
                                        placeholder="Ingrese el correo electrónico"
                                        value={member.email}
                                        onChange={handleValues}
                                        />
                                    <FormInputGroup 
                                        type="password" 
                                        id="password" 
                                        name="password" 
                                        label="Contraseña" 
                                        placeholder="Ingrese la contraseña"
                                        value={member.password}
                                        onChange={handleValues}
                                        />
                                    <FormInputGroup 
                                        type="phone" 
                                        id="phone" 
                                        name="phone" 
                                        label="Teléfono" 
                                        placeholder="Ingrese el teléfono"
                                        value={member.phone}
                                        onChange={handleValues}
                                        />
                                    <FormSelectGroup 
                                        id="gender" 
                                        name="gender" 
                                        label="Genero" 
                                        defaultValue={member.gender}
                                        options={[{value:'',text: 'Genero del miembro'},{value:'m',text: 'Masculino'},{value:'f',text: 'Femenino'}]}
                                        onChange={handleValues}
                                        />
                                    <FormInputGroup 
                                        type="number" 
                                        id="age" 
                                        name="age" 
                                        label="Edad" 
                                        placeholder="Ingrese la edad"
                                        value={member.age}
                                        onChange={handleValues}
                                        />
                                    <FormSelectGroup 
                                        id="rol" 
                                        name="rol" 
                                        label="Rol" 
                                        defaultValue={member.rol}
                                        options={rolLst}
                                        onChange={handleValues}
                                        />
                                    <FormSelectGroup 
                                        id="mType" 
                                        name="mType" 
                                        label="Tipo de cuenta" 
                                        defaultValue={member.mType}
                                        options={[{value:'',text: 'Seleccione un tipo'},{value:'player',text: 'Jugador'},{value:'admin',text: 'Administrador'}]}
                                        onChange={handleValues}
                                        />
                                    {
                                        profile.admin_control!==undefined&&profile.admin_control!==null&&profile.admin_control==='t'?
                                        <>
                                            <FormSelectGroup 
                                                id="adminControl" 
                                                name="adminControl" 
                                                label="Control Administrativo" 
                                                defaultValue={member.adminControl}
                                                options={adminControlContent}
                                                onChange={handleValues}
                                            />
                                            <FormInputGroup 
                                                type="number" 
                                                id="qtyTeams" 
                                                name="qtyTeams" 
                                                label="Cantidad de equipo" 
                                                placeholder="Permitidos para creación"
                                                value={member.qtyTeams}
                                                onChange={handleValues}
                                                />
                                            <FormInputGroup 
                                                type="number" 
                                                id="qtyMembers" 
                                                name="qtyMembers" 
                                                label="Cantidad de miembros" 
                                                placeholder="Permitidos para creación"
                                                value={member.qtyMembers}
                                                onChange={handleValues}
                                                />
                                        </>
                                        :null
                                    }
                                    <Stack gap={2} className="col-md-5 mx-auto">
                                        {
                                            isLoading?
                                                <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                                            :
                                            <>                                        
                                                <CustomButton btn="primary" text={member.id>0?"Actualizar Miembro":"Crear Miembro"} onClick={createMember} />
                                                {
                                                    member.id>0?
                                                    <>
                                                        <CustomButton btn="secondary" text="Limpiar Miembro" onClick={clearMember} />
                                                        <CustomButton btn="danger" text="Eliminar Miembro" onClick={removeMember} />
                                                    </>
                                                    :null
                                                }
                                            </>
                                        }                                
                                    </Stack>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                :null
            }
            <Row>
                <Col>
                    <Stack gap={2} className="col-md-5 mx-auto">
                        {
                            !createForm?
                                <CustomButton btn="primary" text="Crear miembro" onClick={()=>setCreateForm(true)}  />
                            :<CustomButton 
                                btn="danger" 
                                text="Cancelar" 
                                onClick={()=>{
                                    setMember(memberData);
                                    setCreateForm(false)
                                }}  />
                        }                            
                    </Stack>
                </Col>
            </Row>
            </>
            }
        </Container>
        <Container className="ten-height d-flex align-items-end" fluid>
            {/* Menu Footer */}
            <PlayerMenu menu="members" />
        </Container>
    </>
    )
}

export default Members;