import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Table, Spinner } from "react-bootstrap";
import PlayerMenu from "../../Organisms/PlayerMenu";
import { useSelector } from "react-redux";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import AppHeader from "../../Organisms/AppHeader";
import { EnvConfig } from "../../../Lib/EnvConfig";
import Swal from "sweetalert2";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

const PlayerStats = () => {
    const
        [isLoadingData,setIsLoadingData] = useState(false), 
        profile = useSelector((state)=>state.profile.value.payload),
        [player,setPlayer] = useState(null),
        { idMember } = useParams(),
        navigate = useNavigate();
    const getMemberStats = async () => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/member/stats/${idMember}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            setPlayer(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    useEffect(()=>{
        if((profile!==undefined&&profile!==null&&Object.keys(profile).length>0)){
            getMemberStats();
        }        
    // eslint-disable-next-line
    },[]);
    return (        
        <>
            <AppHeader />
            <Container className="eight-height vertical-over">                
                {
                    isLoadingData?
                        <p><Spinner animation="border" role="status" /> Cargando información...</p>
                    :null
                }
                {
                    (profile===undefined||profile===null||Object.keys(profile).length<1)?                    
                        <Row>
                            <Col>
                                Hemos detectado que refrescaste la app de forma no debida, Por favor <NavLink to="/login">Presiona aqui!</NavLink>
                            </Col>
                        </Row>
                    :
                    (player===null)?
                        <Row>
                            <Col>
                                Jugador no encontrado...
                            </Col>
                        </Row>
                    :
                    (!player.status)?
                        <Row>
                            <Col>
                                Algo pasó, favor refrescar la pagina e intentar de nuevo
                            </Col>
                        </Row>
                    :
                    <>
                    <Row>
                        <Col className="text-align-left font-size-24 bold mb-3">
                            <FontAwesomeIcon icon={faCircleArrowLeft} className="cursor" onClick={()=>navigate("/live/stats")} /> Retornar a jugadores
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white mb-3">
                                <Card.Header>Informaciones Generales</Card.Header>
                                <Card.Body>
                                    <Card.Title className="bold text-capitalize">{player.member.m_name}</Card.Title>
                                    <Card.Text className="text-muted">
                                        Estas son las informaciones que tenemos relacionadas a tu perfil
                                    </Card.Text>
                                    <Table responsive borderless>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Jugador</strong><br />
                                                    {player.member.m_name}
                                                </td>
                                                <td>
                                                    <strong>Cuenta</strong><br />
                                                    {player.member.email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Teléfono</strong><br />
                                                    {player.member.phone}
                                                </td>
                                                <td>
                                                    <strong>Genero</strong><br />
                                                    {player.member.gender}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Edad</strong><br />
                                                    {player.member.age}
                                                </td>
                                                <td>
                                                    <strong>Rol</strong><br />
                                                    {player.member.rol}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Tipo de cuenta</strong><br />
                                                    {player.member.m_type}
                                                </td>
                                                <td>
                                                    <strong>Creado por</strong><br />
                                                    {player.member.created_by}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Creado En</strong><br />
                                                    {player.member.created_at}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white mb-3">
                                <Card.Header>Estadísticas en vivo</Card.Header>
                                <Card.Body>
                                    <Card.Title className="text-primary bold">AVG. {player.avg}</Card.Title>
                                    <Card.Text className="text-muted">
                                        Estadísticas General del Jugador
                                    </Card.Text>
                                    <Table responsive borderless>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} className="text-success bold">+Positivas</td>
                                            </tr>
                                            {player.positiveStats.map((element, idx) => {
                                                return (<tr key={`positive-${idx}`}>
                                                    <td style={{width: '50%'}} className="text-align-right"><strong>{element.metric}</strong></td>
                                                    <td style={{width: '50%'}} className="text-align-left">{element.quantity}</td>
                                                </tr>);
                                            })}
                                            <tr>
                                                <td colSpan={2} className="text-secondary bold">=Neutral</td>
                                            </tr>
                                            {player.neutralStats.map((element, idx) => {
                                                return (<tr key={`positive-${idx}`}>
                                                    <td style={{width: '50%'}} className="text-align-right"><strong>{element.metric}</strong></td>
                                                    <td style={{width: '50%'}} className="text-align-left">{element.quantity}</td>
                                                </tr>);
                                            })}
                                            <tr>
                                                <td colSpan={2} className="text-danger bold">-Negativas</td>
                                            </tr>
                                            {player.negativeStats.map((element, idx) => {
                                                return (<tr key={`negative-${idx}`}>
                                                    <td style={{width: '50%'}} className="text-align-right"><strong>{element.metric}</strong></td>
                                                    <td style={{width: '50%'}} className="text-align-left">{element.quantity}</td>
                                                </tr>);
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white">
                                <Card.Header>Mis Equipos</Card.Header>
                                <Card.Body>
                                    <Card.Title className="bold">Equipos del miembro</Card.Title>
                                    <Card.Text className="text-muted">
                                        {
                                            profile.teams?
                                                profile.teams.map((el,ix)=>{
                                                    return (
                                                        <Row key={`p-${ix}`} className="mb-3">
                                                            <Col>
                                                                <span className="bold font-size-24 text-primary">{el.team}</span><br />
                                                                <span>Deporte. {el.sport}</span><br />
                                                                <span>Tipo. {el.t_team}</span><br />
                                                                <span>Ganados. {el.wins}</span><br />
                                                                <span>Perdidos. {el.loses}</span>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            :null
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    </>
                }                
            </Container>
            <Container className="ten-height d-flex align-items-end" fluid>
                {/* Menu Footer */}
                <PlayerMenu menu="resume" />
            </Container>
        </>        
    )
}

export default PlayerStats;