import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EnvConfig } from "../../../Lib/EnvConfig";
import Swal from "sweetalert2";
import AppHeader from "../../Organisms/AppHeader";
import { Card, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import PlayerMenu from "../../Organisms/PlayerMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../Atoms/CustomButton";

const TeamGames = () => {
    const [isLoadingData,setIsLoadingData] = useState(false),
          [teams,setTeams] = useState([]),
          profile = useSelector((state)=>state.profile.value.payload),
          navigate = useNavigate(),
          { idGame } = useParams(),
          [isLoading,setIsLoading] = useState(false),
          [inning,setInning] = useState(0)
        ;
    let initPost = false;
    const chargeTeams = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/team/by/game/${idGame}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;  
            if(data.teams){
                setTeams(data.teams);
            }
            if(data.game){
                setInning(data.game.current_inning);
            }
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    useEffect(()=>{
        if((profile!==undefined&&profile!==null&&Object.keys(profile).length>0)&&initPost===false){
            chargeTeams();
            // eslint-disable-next-line
            initPost = true;
        }
    // eslint-disable-next-line
    },[]);
    const goToPlayers = (card) => {
        const id = card.currentTarget.getAttribute("id");
        if(id){
            navigate(`/team/players/${idGame}/${id}/${inning}`);
        }
    }
    const changeInning = async() => {
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/change/game/innings`, {id_game: idGame}, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            // Swal.fire({
            //     icon: "success",
            //     title: "Operación Exitosa",
            //     text: data.message
            // });
            setInning(data.currentInning);
            setIsLoading(false);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    return (
        <>
            <AppHeader />
            <Container className="eight-height vertical-over">                
                {
                    isLoadingData?
                        <p><Spinner animation="border" role="status" /> Cargando información...</p>
                    :null
                }
                {
                    teams?
                        <Row>
                            <Col className="bold mb-3" style={{fontSize: '24px'}}>
                                <hr />
                                <FontAwesomeIcon icon={faCircleArrowLeft} className="cursor" onClick={()=>navigate("/game/reported")} /> Equipos En Juego
                            </Col>
                        </Row>
                    :null
                }
                <Row>
                    <Col className="text-align-center font-size-32 text-success bold mb-3">
                        Inning{`#${inning}`}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Stack gap={2} className="col-md-5 mx-auto mb-3">
                        {
                            isLoading?
                                <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                            :<CustomButton btn="primary" text="Cambio de Inning" onClick={changeInning} />
                        }
                        </Stack>
                    </Col>
                </Row>
                {
                    teams?
                    teams.map((elm,idx)=>{
                            return (
                                <Row key={`team-game-${idx}`}>
                                    <Col>
                                        <Card id={elm.id} key={idx} className="mb-3 cursor" onClick={goToPlayers}>
                                            <Card.Body>
                                                <Card.Title className="text-primary">{elm.description}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-align-right font-size-24 bold text-success">Carreras. {elm.runs}</Card.Subtitle>
                                                {/* <Card.Subtitle className="mb-2 text-muted">Innings. {elm.innings}</Card.Subtitle> */}
                                                <Card.Subtitle className="mb-2 text-muted">Hits. {elm.hits}</Card.Subtitle>
                                                <Card.Subtitle className="mb-2 text-muted">Outs. {elm.errors}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        })
                    :null
                }
            </Container>
            <Container className="ten-height d-flex align-items-end" fluid>
                {/* Menu Footer */}
                <PlayerMenu />
            </Container>
        </>
    )
}

export default TeamGames;