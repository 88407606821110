import React, {useState, useEffect} from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import FormInputGroup from "../../Molecules/FormInputGroup";
import StackLoginBtns from "../../Molecules/StackLoginBtns";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { create } from "../../../Features/Profile/profileSlice";
import { EnvConfig } from "../../../Lib/EnvConfig";
import { useNavigate } from "react-router-dom";
const credentials = {
    email: "",
    password: ""
}
const Login = () => {
    const [usrCredentials,setUsrCredentials] = useState(credentials),
            dispatch = useDispatch(),
            profile = useSelector((state)=>(state.profile.value!==null&&state.profile.value!==undefined&&Object.keys(state.profile.value).length>0)?state.profile.value.payload:null),
            [isLoading,setIsLoading] = useState(false),
            navigate = useNavigate();
    const checkTokenSession = async() => {
        const token = sessionStorage.getItem('BTYAPP-KEY-TOKEN');
        if(token!==undefined&&token!==null&&token.length>0){
            setIsLoading(true);
            try{
                const get = await axios.get(`${EnvConfig.host}v1/profile/${token}`),
                    data = await get.data;
                if(data.status){
                    const reduxObject = {
                        ...data.member,
                        positiveStats: data.positiveStats,
                        negativeStats: data.negativeStats,
                        hits: data.hits,
                        turns: data.turns,
                        avg: data.avg,
                        teams: data.teams,
                        parentAccounts: data.parentAccounts,
                        token: data.member.token
                    }
                    dispatch(create(reduxObject));
                }
                setIsLoading(false);
            } catch(error){
                setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        }
    }
    useEffect(()=>{
        checkTokenSession();
    // eslint-disable-next-line
    },[]);
    const handleValues = (input) => {
        const key = input.target.id,
            value = input.target.value;
        setUsrCredentials({
            ...usrCredentials,
            [key]: value
        })
    }
    const valid = () => {
        if(usrCredentials.email.length<1){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "Debe ingresar un correo electrónico",
                confirmButtonColor: '#f00'
            });
            return false;
        }
        if(usrCredentials.password.length<1){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "Debe ingresar una contraseña",
                confirmButtonColor: '#f00'
            });
            return false;
        }
        return true;
    }
    const logIn = async() => {
        if(valid()){
            setIsLoading(true);
            try{
                const post = await axios.post(`${EnvConfig.host}v1/login`, usrCredentials),
                    data = await post.data;
                if(!data.status){
                    setIsLoading(false);
                    Swal.fire({
                        icon: "warning",
                        title: "Advertencia",
                        text: data.message,
                        confirmButtonColor: '#f00'
                    });
                    return;
                }
                const reduxObject = {
                    ...data.member,
                    positiveStats: data.positiveStats,
                    negativeStats: data.negativeStats,
                    neutralStats: data.neutralStats,
                    hits: data.hits,
                    turns: data.turns,
                    avg: data.avg,
                    teams: data.teams,
                    parentAccounts: data.parentAccounts,
                    token: data.member.token
                }
                sessionStorage.setItem('BTYAPP-KEY-TOKEN', data.token);
                dispatch(create(reduxObject));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        }
    }
    return (
        <Container>
            <Row className="d-flex align-items-center justify-content-md-center total-height">
                <Col xs={12} lg={6}>
                    <Card className="bg-white">
                        <Card.Body>
                            {
                                profile!==undefined&&profile!==null&&Object.keys(profile).length?
                                <>
                                    <Card.Title className="text-align-center">Cargando las informaciones de tu perfil <Spinner animation="border" role="status" className="text-align-center" /></Card.Title>
                                    {navigate("/dashboard")}
                                </>
                                :
                                <>
                                    <Card.Title className="text-align-center">Bienvenido a BtyApp</Card.Title>
                                    <Card.Text className="text-align-center">
                                        Aplicación para control y manejo de estadísticas deportivas
                                    </Card.Text>
                                    <FormInputGroup 
                                        type="email" 
                                        id="email" 
                                        name="email" 
                                        label="Correo Electrónico" 
                                        placeholder="Ingrese su correo electrónico"
                                        value={usrCredentials.email}
                                        onChange={handleValues}
                                        />
                                    <FormInputGroup 
                                        type="password" 
                                        id="password" 
                                        name="password" 
                                        label="Contraseña" 
                                        placeholder="Ingrese su contraseña"
                                        value={usrCredentials.password}
                                        onChange={handleValues}
                                        />
                                    <StackLoginBtns logIn={logIn} isLoading={isLoading} />                                    
                                    <Card.Text className="text-align-center">
                                        {`v${EnvConfig.version}`}
                                    </Card.Text>
                                </>
                            }                                                       
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;