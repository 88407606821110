import { faCog, faHandshakeSimple, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const PlayerMenu = (props) => {
    const { menu } = props,
        navigate = useNavigate();
    const callView = (element) => {
        const id = element.currentTarget.getAttribute("id");
        switch(id){
            case "resume":
                navigate("/dashboard")
                break;
            case "members":
                navigate("/members")
                break;
            case "team":
                navigate("/team")
                break;
            case "profile":
                navigate("/profile")
                break;
            default:
                break;
        }
    }
    return (
        <Row className="bg-white total-width">
            <Col xs={3} lg={3} id="resume" className={`footer-option-menu text-align-center ${menu==="resume"?"footer-option-active":""}`} onClick={callView}>
                <FontAwesomeIcon icon={faUser} className={`footer-option-icon`} /> <br /> Resumen
            </Col>
            <Col xs={3} lg={3} id="team" className={`footer-option-menu text-align-center ${menu==="team"?"footer-option-active":""}`} onClick={callView}>
                <FontAwesomeIcon icon={faHandshakeSimple} className="footer-option-icon" /> <br /> Equipos
            </Col>
            <Col xs={3} lg={3} id="members" className={`footer-option-menu text-align-center ${menu==="members"?"footer-option-active":""}`} onClick={callView}>
                <FontAwesomeIcon icon={faUsers} className={`footer-option-icon`} /> <br /> Miembros
            </Col>
            <Col xs={3} lg={3} id="profile" className={`footer-option-menu text-align-center ${menu==="profile"?"footer-option-active":""}`} onClick={callView}>
                <FontAwesomeIcon icon={faCog} className="footer-option-icon" /> <br /> Perfil
            </Col>
        </Row>
    )
}

PlayerMenu.propTypes = {
    menu: PropTypes.string
}

export default PlayerMenu;