import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import Label from "../../Atoms/Label";
import Select from "../../Atoms/Select";

const FormSelectGroup = (props) => {
    const { 
        label, 
        id, 
        name, 
        onChange, 
        defaultValue, 
        subTitle, 
        options,
        showLabel,
        dataExtra 
    } = props;
    return (
        <Form.Group className="mb-3">
            {(showLabel===undefined||showLabel)?<Label text={label} />:null}
            <Select id={id} extra={dataExtra} name={name} onChange={onChange} defaultValue={defaultValue} options={options} />
            {
                subTitle?
                    <Form.Text className="text-muted">
                        {subTitle}
                    </Form.Text>
                :null
            }            
        </Form.Group>
    )
}

FormSelectGroup.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    subTitle: PropTypes.string
}

export default FormSelectGroup;