import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import Label from "../../Atoms/Label";
import Input from "../../Atoms/Input";

const FormInputGroup = (props) => {
    const { label, type, id, name, placeholder, onChange, value, subTitle } = props;
    return (
        <Form.Group className="mb-3">
            <Label text={label} />
            <Input type={type} id={id} name={name} placeholder={placeholder} onChange={onChange} value={value} />
            {
                subTitle?
                    <Form.Text className="text-muted">
                        {subTitle}
                    </Form.Text>
                :null
            }            
        </Form.Group>
    )
}

FormInputGroup.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    subTitle: PropTypes.string
}

export default FormInputGroup;