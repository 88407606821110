//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { RoutesLst } from './Lib/Router/routes';

function App() {
  return (
    <Router>
      <Routes>
        {RoutesLst}
      </Routes>
    </Router>
  );
}

export default App;
