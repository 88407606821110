import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Modal, Row, Spinner, Stack, Tab, Table, Tabs } from "react-bootstrap";
import CustomButton from "../../Atoms/CustomButton";
import { EnvConfig } from "../../../Lib/EnvConfig";
import AppHeader from "../../Organisms/AppHeader";
import { useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import FormInputGroup from "../../Molecules/FormInputGroup";
import PlayerMenu from "../../Organisms/PlayerMenu";
import FormSelectGroup from "../../Molecules/FormSelectGroup";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const gameData = {
    id: 0,
    description: "",
    innings: 0    
}

const Game = () => {
    const [game,setGame] = useState(gameData),
            profile = useSelector((state)=>state.profile.value.payload),
          [isLoading,setIsLoading] = useState(false),
          [isLoadingData,setIsLoadingData] = useState(false),
          [games,setGames] = useState([]),
          [lineUpModal,setLineUpModal] = useState(false),
          [gameSelected,setGameSelected] = useState(0),
          [homeTeam,setHomeTeam] = useState(0),
          [guestTeam,setGuestTeam] = useState(0),
          [homeMemberTeam,setHomeMemberTeam] = useState([]),
          [guestMemberTeam,setGuestMemberTeam] = useState([]),
          [myTeamsLst,setMyTeamsLst] = useState([]),
          [baseLst,setBaseLst] = useState([]),
          [homeTeamSelected,setHomeTeamSelected] = useState(null),
          [guestTeamSelected,setGuestTeamSelected] = useState(null),
          [currentPlayerLineUp,] = useState(0),
          [newPlayerLineUp,] = useState(0),
        //   [memberForChange,setMemberForChange] = useState([]),
        //   [memberForChangeGuest,setMemberForChangeGuest] = useState([]),
        //   [memberPlayerLineUpChange,setMemberPlayerLineUpChange] = useState(0),
        //   [memberPlayerLineUpChangeGuest,setMemberPlayerLineUpChangeGuest] = useState(0),
          [createForm,setCreateForm] = useState(false);
    const handleValues = (input) => {
        const key = input.target.id,
            value = input.target.value;
        setGame({
            ...game,
            [key]: value
        })
    }
    const createGame = async() => {
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/game/save`, game, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: data.message
            });
            setGame(gameData);
            setGames(data.myGames);
            setCreateForm(false);
            setIsLoading(false);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const chargeGames = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/usr/games/${profile.id}/true`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            setGames(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const generalInfo = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/generals`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            let 
                myTeams = [],
                myTeamsResource = data.myTeams,
                bases = [],
                basesResource = data.bases;

                myTeams.push({value: 0, text: "Mis Equipos"})
                bases.push({value: 0, text: "Selecciona una base"})
            for(let counter = 0; counter < myTeamsResource.length; counter++){
                myTeams.push({
                    value: myTeamsResource[counter].id,
                    text: myTeamsResource[counter].description
                });
            }
            for(let counter = 0; counter < basesResource.length; counter++){
                bases.push({
                    value: basesResource[counter].id,
                    text: basesResource[counter].description
                });
            }
            setMyTeamsLst(myTeams);
            setBaseLst(bases);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    useEffect(()=>{
        if((profile!==undefined&&profile!==null&&Object.keys(profile).length>0)){
            chargeGames();
            generalInfo();
        }        
    // eslint-disable-next-line
    },[]);
    const homeChargeTeam = async(input)=>{
        const value = input.target.value;
        setHomeTeam(value);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/team/players/${value}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
            data = await get.data;
            setHomeMemberTeam(data);
        } catch(error){
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const guestChargeTeam = async(input)=>{
        const value = input.target.value;
        setGuestTeam(value);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/team/players/${value}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
            data = await get.data;
            setGuestMemberTeam(data);
        } catch(error){
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const createLineUp = async(card) => {
        const id = card.currentTarget.getAttribute("id");
        if(id){
            setHomeTeamSelected(null);
            setGuestTeamSelected(null);
            setHomeMemberTeam([]);
            setGuestMemberTeam([]);
            setHomeTeam(0);
            setGuestTeam(0);

            setGameSelected(id);
            setIsLoadingData(true);
            try{
                //`${EnvConfig.host}v1/game/lineups/${id}`
                const get = await axios.get(`${EnvConfig.host}v1/teams/game/lineup/${id}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                    data = await get.data;

                setHomeTeamSelected(data.home);
                setGuestTeamSelected(data.guest);
                setIsLoadingData(false);
                setLineUpModal(true);
            } catch(error){
                setIsLoadingData(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        } else {
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "Juego no encontrado",
                confirmButtonColor: '#f00'
            });
            return;
        }
    }
    const saveHomeLineUp = async() => {
        if( parseInt(homeTeam) === parseInt(guestTeam) ){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "No puedes tener equipos iguales seleccionados",
                confirmButtonColor: '#f00'
            });
            return;
        }
        if(homeMemberTeam.length<1){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "No se ha encontrado el line up para este equipo",
                confirmButtonColor: '#f00'
            });
            return;
        }
        let players = []
        for(let counter=0;counter<homeMemberTeam.length;counter++){
            players.push({
                id_member: homeMemberTeam[counter].value,
                id_base: homeMemberTeam[counter].id_base,
                turn_no: homeMemberTeam[counter].turn_no
            })
        }
        if(players.length<1){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "No se ha encontrado el line up para este equipo",
                confirmButtonColor: '#f00'
            });
            return;
        }
        setIsLoading(true);

        try{
            const post = await axios.post(`${EnvConfig.host}v1/lineup/save`, {
                lineUp: {
                    id_game: gameSelected,                    
                    id_team: homeTeam,
                    teamRef: "home"
                },
                players: players
            }, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: data.message
            });
            setHomeTeamSelected(data.lineups.home);
            setIsLoading(false);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const changeTeamPlayerBase = (element) => {
        const idx = element.currentTarget.getAttribute("id"),
            value = element.currentTarget.value;
        let homeMemberTeamLocal = [...homeMemberTeam];
        homeMemberTeamLocal[idx].id_base = value;
        setHomeMemberTeam(homeMemberTeamLocal);
    }
    const changeTeamPlayerTurn = (element) => {
        const 
            idxSplit = element.currentTarget.getAttribute("id").split("-"),
            idx = idxSplit[2],
            value = element.currentTarget.value;        
        let homeMemberTeamLocal = [...homeMemberTeam];
        homeMemberTeamLocal[idx].turn_no = value;
        setHomeMemberTeam(homeMemberTeamLocal);
    }
    const changeGuestTeamPlayerBase = (element) => {
        const idx = element.currentTarget.getAttribute("id"),
            value = element.currentTarget.value;
        let guestMemberTeamLocal = [...guestMemberTeam];
        guestMemberTeamLocal[idx].id_base = value;
        setGuestMemberTeam(guestMemberTeamLocal);
    }
    const changeGuestTeamPlayerTurn = (element) => {
        const 
            idxSplit = element.currentTarget.getAttribute("id").split("-"),
            idx = idxSplit[3],
            value = element.currentTarget.value;
        let guestMemberTeamLocal = [...guestMemberTeam];
        guestMemberTeamLocal[idx].turn_no = value;
        setGuestMemberTeam(guestMemberTeamLocal);
    }
    const saveGuestLineUp = async() => {
        if( parseInt(homeTeam) === parseInt(guestTeam) ){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "No puedes tener equipos iguales seleccionados",
                confirmButtonColor: '#f00'
            });
            return;
        }
        if(guestMemberTeam.length<1){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "No se ha encontrado el line up para este equipo",
                confirmButtonColor: '#f00'
            });
            return;
        }
        let players = []
        for(let counter=0;counter<guestMemberTeam.length;counter++){
            players.push({
                id_member: guestMemberTeam[counter].value,
                id_base: guestMemberTeam[counter].id_base,
                turn_no: guestMemberTeam[counter].turn_no
            })
        }
        if(players.length<1){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "No se ha encontrado el line up para este equipo",
                confirmButtonColor: '#f00'
            });
            return;
        }
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/lineup/save`, {
                lineUp: {
                    id_game: gameSelected,                    
                    id_team: guestTeam,
                    teamRef: "guest"
                },
                players: players
            }, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: data.message
            });
            setGuestTeamSelected(data.lineups.guest);
            setIsLoading(false);            
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    // const changeLineUpPlayer = async(element) => {
    //     element.preventDefault();
    //     const id = element.currentTarget.getAttribute("id");
    //     setCurrentPlayerLineUp(id);
    //     setIsLoading(true);
    //     try{
    //         const get = await axios.get(`${EnvConfig.host}v1/team/players/${homeTeamSelected.lineUp.idTeam}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
    //         data = await get.data;
    //         setMemberForChange(data);
    //         setIsLoading(false);
    //     } catch(error){
    //         setIsLoading(false);
    //         Swal.fire({
    //             icon: "error",
    //             title: "Error Interno",
    //             text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
    //             confirmButtonColor: '#f00'
    //         });
    //     }
    // }
    // const changePlayerInLineUp = async() => {
    //     setIsLoading(true);
    //     try{
    //         let datos = { 
    //             current: currentPlayerLineUp,
    //             new: memberPlayerLineUpChange,
    //             game: gameSelected,
    //             team: homeTeamSelected.lineUp.idTeam
    //         }
    //         const post = await axios.post(`${EnvConfig.host}v1/update/player/in/line/up`, datos, { headers: { Authorization: `Bearer ${profile.token}`}}),
    //         data = await post.data;
    //         if(!data.status){
    //             Swal.fire({
    //                 icon: "warning",
    //                 title: "Advertencia",
    //                 text: data.message,
    //                 confirmButtonColor: '#f00'
    //             });
    //             setIsLoading(false);
    //             return;
    //         }
    //         Swal.fire({
    //             icon: "success",
    //             title: "Operación Exitosa",
    //             text: data.message
    //         });
    //         setHomeTeamSelected(data.lineUps.home);
    //         setCurrentPlayerLineUp(0);
    //         setMemberForChange([]);
    //         setIsLoading(false);
    //     } catch(error){
    //         setIsLoading(false);
    //         Swal.fire({
    //             icon: "error",
    //             title: "Error Interno",
    //             text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
    //             confirmButtonColor: '#f00'
    //         });
    //     }
    // }
    // const changeLineUpPlayerGuest = async(element) => {
    //     element.preventDefault();
    //     const id = element.currentTarget.getAttribute("id");
    //     setNewPlayerLineUp(id);
    //     setIsLoading(true);
    //     try{
    //         const get = await axios.get(`${EnvConfig.host}v1/team/players/${guestTeamSelected.lineUp.idTeam}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
    //         data = await get.data;
    //         setMemberForChangeGuest(data);
    //         setIsLoading(false);
    //     } catch(error){
    //         setIsLoading(false);
    //         Swal.fire({
    //             icon: "error",
    //             title: "Error Interno",
    //             text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
    //             confirmButtonColor: '#f00'
    //         });
    //     }
    // }
    // const changePlayerInLineUpGuest = async() => {
    //     setIsLoading(true);
    //     try{
    //         let datos = { 
    //             current: newPlayerLineUp,
    //             new: memberPlayerLineUpChangeGuest,
    //             game: gameSelected,
    //             team: guestTeamSelected.lineUp.idTeam
    //         }
    //         const post = await axios.post(`${EnvConfig.host}v1/update/player/in/line/up`, datos, { headers: { Authorization: `Bearer ${profile.token}`}}),
    //         data = await post.data;
    //         if(!data.status){
    //             Swal.fire({
    //                 icon: "warning",
    //                 title: "Advertencia",
    //                 text: data.message,
    //                 confirmButtonColor: '#f00'
    //             });
    //             setIsLoading(false);
    //             return;
    //         }
    //         Swal.fire({
    //             icon: "success",
    //             title: "Operación Exitosa",
    //             text: data.message
    //         });
    //         setGuestTeamSelected(data.lineUps.guest);
    //         setNewPlayerLineUp(0);
    //         setMemberForChangeGuest([]);
    //         setIsLoading(false);
    //     } catch(error){
    //         setIsLoading(false);
    //         Swal.fire({
    //             icon: "error",
    //             title: "Error Interno",
    //             text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
    //             confirmButtonColor: '#f00'
    //         });
    //     }
    // }
    const removeGame = async() => {
        setIsLoading(true);
        try{
            const post = await axios.post(
                `${EnvConfig.host}v1/remove/game`, 
                {
                    id_game: parseInt(gameSelected)
                }, 
                { headers: { Authorization: `Bearer ${profile.token}`}}
            ),
            data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: data.message
            });
            setGames(data.games);
            setIsLoading(false);
            setLineUpModal(false);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const cleanLineUp = async (id, homeTeam) => {
        setIsLoading(true);
        try {
            const post = await axios.post(
                `${EnvConfig.host}v1/lineup/remove`, 
                {
                    id: parseInt(id)
                }, 
                { headers: { Authorization: `Bearer ${profile.token}`}}
            ),
            data = await post.data;
            if(!data.status)
            {
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }

            if ( homeTeam )
                setHomeTeamSelected(null);
            else
                setGuestTeamSelected(null);

            setIsLoading(false);

        } catch ( error ) {
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const newHomeLineUp = () => {
        console.log('homeTeamSelected>>', homeTeamSelected);
        cleanLineUp(homeTeamSelected.lineUp.id, true);
    }
    const newGuestLineUp = () => {
        console.log('guestTeamSelected>>', guestTeamSelected);
        cleanLineUp(guestTeamSelected.lineUp.id, false);
    }
    return (
        <>
            <AppHeader />
            <Container className="eight-height vertical-over">                
                {
                    isLoadingData?
                        <Row>
                            <Col className="text-align-center">
                                <p><Spinner animation="border" role="status" /> Cargando información...</p>
                            </Col>
                        </Row>
                    :null
                }
                {
                    (profile===undefined||profile===null||Object.keys(profile).length<1)?                    
                        <Row>
                            <Col>
                                <p>Hemos detectado que refrescaste la app de forma no debida, Por favor <NavLink to="/login">Presiona aqu!</NavLink></p>
                            </Col>
                        </Row>
                    :
                    profile.m_type!=='admin'?
                        <Row>
                            <Col>
                                <p>No tienes permisos para visualizar estas informaciones... Contacta al administrador</p>
                            </Col>
                        </Row>
                    :
                    <>
                        {
                            createForm?
                                <Row>
                                    <Col xs={12} lg={12}>
                                        <Card className="text-center bg-white mb-3">
                                            <Card.Header>Abrir Juego</Card.Header>
                                            <Card.Body>
                                                <Card.Title className="bold text-capitalize">Crea Un Nuevo Juego</Card.Title>
                                                <Card.Text className="text-muted">
                                                    Una vez creado el juego, crea las alineaciones por equipos.
                                                </Card.Text>
                                                <FormInputGroup 
                                                    type="text" 
                                                    id="description" 
                                                    name="description" 
                                                    label="Descripción" 
                                                    placeholder="Descripción del juego"
                                                    value={game.description}
                                                    onChange={handleValues}
                                                    />
                                                <FormInputGroup 
                                                    type="number" 
                                                    id="innings" 
                                                    name="innings" 
                                                    label="Innings" 
                                                    placeholder="Cantidad de innings"
                                                    value={game.innings}
                                                    onChange={handleValues}
                                                    />
                                                <Stack gap={2} className="col-md-5 mx-auto">
                                                    {
                                                        isLoading?
                                                            <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                                                        :<CustomButton btn="primary" text="Crear Juego" onClick={createGame} />
                                                    }
                                                    {/* <CustomButton btn="outline-secondary" text="Crear LineUp" /> */}
                                                </Stack>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            : null
                        }
                        {
                            !createForm&&games!==undefined&&games!==null&&games.length>0?
                                <Row>
                                    <Col className="bold mb-3" style={{fontSize: '18px'}}>
                                        Juegos
                                    </Col>
                                </Row>
                            :null
                        }
                        {
                            !createForm&&games!==undefined&&games!==null&&games.length>0?
                                games.map((elm,idx)=>{
                                    return (
                                        <Card id={elm.id} key={idx} style={{ width: '100%' }} className="mb-3 cursor" onClick={createLineUp}>
                                            <Card.Body>
                                                <Card.Subtitle className="text-align-right font-size-24 bold text-success"><FontAwesomeIcon icon={faUsers} /></Card.Subtitle>
                                                <Card.Title>{elm.description}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted">Innings. {elm.innings}</Card.Subtitle>
                                                <Card.Subtitle className="mb-2 bold font-size-12 text-primary">Si aún no ha creado la alineaciones, presiona aquí!</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                    )
                                })
                            :null
                        }
                        <Row>
                            <Col>
                                <Stack gap={2} className="col-md-5 mx-auto">
                                    {
                                        !createForm?
                                            <CustomButton btn="primary" text="Abrir juego" onClick={()=>setCreateForm(true)}  />
                                        :<CustomButton 
                                            btn="danger" 
                                            text="Cancelar" 
                                            onClick={()=>{
                                                setGame(gameData);
                                                setCreateForm(false)
                                            }}  />
                                    }                            
                                </Stack>
                            </Col>
                        </Row>
                    </>
                }                
            </Container>
            <Container className="ten-height d-flex align-items-end" fluid>
                {/* Menu Footer */}
                <PlayerMenu />
            </Container>
            {
            (profile!==undefined&&profile!==null&&Object.keys(profile).length>0)?                    
                <Modal show={lineUpModal} onHide={()=>{setLineUpModal(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Crear line up de los equipos
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Stack gap={2} className="col-md-5 mx-auto mb-3">
                                        <CustomButton btn="danger" text="Eliminar Juego" onClick={removeGame} />
                                    </Stack>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Tabs
                                        defaultActiveKey="home-team"
                                        id="lineup-teams"
                                        className="mb-3"
                                        >
                                        <Tab eventKey="home-team" title="Casa">
                                            {
                                                (homeTeamSelected!==undefined&&homeTeamSelected!==null&&homeTeamSelected.lineUp!==undefined&&homeTeamSelected.lineUp!==null)?
                                                <>
                                                    <Container fluid>
                                                        <Row className="mb-3">
                                                            <Col className="bold text-success h2">{homeTeamSelected.lineUp.team}</Col>
                                                            <Col>
                                                                <Button variant="success" onClick={newHomeLineUp}>
                                                                    Nuevo Line Up
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Table responsive bordered hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-center">Turno</th>
                                                                            <th className="text-center">Jugador</th>
                                                                            <th className="text-center">Posición</th>
                                                                        </tr>
                                                                        {/* <tr>
                                                                            <th colSpan={2} style={{textAlign: 'center'}} className="text-success">Puedes cambiar el lineup seleccionando al jugador</th>                                                    
                                                                        </tr> */}
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            (homeTeamSelected.players)?
                                                                                homeTeamSelected.players.map((element,idx)=>{
                                                                                    return (
                                                                                        <tr key={`tr-selected-key-${idx}`} className={currentPlayerLineUp === element.id_member?"selected":""}>
                                                                                            <td>{element.turn_no}</td>
                                                                                            {/* <td id={element.id_member} onClick={changeLineUpPlayer} className="player-option font-size-16 text-primary">{element.player}</td> */}
                                                                                            <td className="font-size-16">{element.player}</td>
                                                                                            <td>{element.base}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            :null
                                                                        }
                                                                    </tbody>
                                                                    {
                                                                        // isLoading?
                                                                        //     <Row>
                                                                        //         <Col className="text-align-center">
                                                                        //             <Spinner animation="border" role="status" /> Cargando información...
                                                                        //         </Col>
                                                                        //     </Row>
                                                                        // :
                                                                        // memberForChange.length?
                                                                        // <tfoot>
                                                                        //     <tr>
                                                                        //         <th>
                                                                        //             <FormSelectGroup 
                                                                        //                 id="change-line-up-player" 
                                                                        //                 name="change-line-up-player" 
                                                                        //                 label="Jugadores de casa" 
                                                                        //                 defaultValue={memberPlayerLineUpChange}
                                                                        //                 options={memberForChange}
                                                                        //                 onChange={(e)=>setMemberPlayerLineUpChange(e.currentTarget.value)}
                                                                        //             />
                                                                        //         </th>
                                                                        //         <th>
                                                                        //             <label style={{color: "#fff"}}>-</label>
                                                                        //             {
                                                                        //                 isLoading?
                                                                        //                     <p><Spinner animation="border" role="status" /> Cargando información...</p>
                                                                        //                 :
                                                                        //                 <Button variant="secondary" onClick={changePlayerInLineUp}>
                                                                        //                     Cambiar Jugador
                                                                        //                 </Button>
                                                                        //             }                                                            
                                                                        //         </th>
                                                                        //     </tr>
                                                                        // </tfoot>
                                                                        // :null
                                                                    }
                                                                </Table>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </>
                                            :
                                                <>
                                                    <FormSelectGroup 
                                                        id="home_team" 
                                                        name="home_team" 
                                                        label="Equipo de casa" 
                                                        defaultValue={homeTeam}
                                                        options={myTeamsLst}
                                                        onChange={homeChargeTeam}
                                                        showLabel={false}
                                                    />
                                                    <Row>
                                                        <Col>
                                                            <Table responsive bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center">Turno</th>
                                                                        <th className="text-center">Jugador</th>
                                                                        <th className="text-center">Posición</th>
                                                                    </tr>                                        
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        homeMemberTeam.map((element,idx)=>{
                                                                            return (
                                                                                <tr key={`tr-key-${idx}`}>
                                                                                    <td>
                                                                                        <FormSelectGroup 
                                                                                            id={`turn-option-${idx}`} 
                                                                                            name={`turn-option-${idx}`}
                                                                                            extra={idx}
                                                                                            label="Turno" 
                                                                                            defaultValue={element.turn_no}
                                                                                            options={[
                                                                                                {
                                                                                                    value: 0,
                                                                                                    text: "Turno"
                                                                                                },
                                                                                                {
                                                                                                    value: 1,
                                                                                                    text: "1"
                                                                                                },
                                                                                                {
                                                                                                    value: 2,
                                                                                                    text: "2"
                                                                                                },
                                                                                                {
                                                                                                    value: 3,
                                                                                                    text: "3"
                                                                                                },
                                                                                                {
                                                                                                    value: 4,
                                                                                                    text: "4"
                                                                                                },
                                                                                                {
                                                                                                    value: 5,
                                                                                                    text: "5"
                                                                                                },
                                                                                                {
                                                                                                    value: 6,
                                                                                                    text: "6"
                                                                                                },
                                                                                                {
                                                                                                    value: 7,
                                                                                                    text: "7"
                                                                                                },
                                                                                                {
                                                                                                    value: 8,
                                                                                                    text: "8"
                                                                                                },
                                                                                                {
                                                                                                    value: 9,
                                                                                                    text: "9"
                                                                                                },
                                                                                                {
                                                                                                    value: 10,
                                                                                                    text: "10"
                                                                                                },
                                                                                                {
                                                                                                    value: 11,
                                                                                                    text: "11"
                                                                                                },
                                                                                                {
                                                                                                    value: 12,
                                                                                                    text: "12"
                                                                                                },
                                                                                                {
                                                                                                    value: 13,
                                                                                                    text: "13"
                                                                                                },
                                                                                                {
                                                                                                    value: 14,
                                                                                                    text: "14"
                                                                                                },
                                                                                                {
                                                                                                    value: 15,
                                                                                                    text: "15"
                                                                                                }
                                                                                            ]}
                                                                                            onChange={changeTeamPlayerTurn}
                                                                                            showLabel={false}
                                                                                        />
                                                                                    </td>
                                                                                    <td>{element.text}</td>
                                                                                    <td>
                                                                                        <FormSelectGroup 
                                                                                            id={`${idx}`} 
                                                                                            name={`base-option-${idx}`}
                                                                                            label="Posición" 
                                                                                            defaultValue={element.id_base}
                                                                                            options={baseLst}
                                                                                            onChange={changeTeamPlayerBase}
                                                                                            showLabel={false}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>                                    
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="mb-3">
                                                        {
                                                            isLoading?
                                                                <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                                                            :
                                                                <Button variant="primary" onClick={saveHomeLineUp}>
                                                                    Guardar Line Up
                                                                </Button>
                                                        }                                
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </Tab>
                                        <Tab eventKey="guest-team" title="Invitado">
                                            {
                                                (guestTeamSelected!==undefined&&guestTeamSelected!==null&&guestTeamSelected.lineUp!==undefined&&guestTeamSelected.lineUp!==null)?
                                                <>
                                                    <Row className="mb-3">
                                                        <Col className="bold text-danger h2">{guestTeamSelected.lineUp.team}</Col>
                                                        <Col>
                                                            <Button variant="success" onClick={newGuestLineUp}>
                                                                Nuevo Line Up
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Table responsive bordered hover>
                                                                <thead>                                                
                                                                    <tr>
                                                                        <th className="text-center">Turno</th>
                                                                        <th className="text-center">Jugador</th>
                                                                        <th className="text-center">Posición</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        (guestTeamSelected.players)?
                                                                        guestTeamSelected.players.map((element,idx)=>{
                                                                            return (
                                                                                <tr key={`tr-selected-key-${idx}`} className={newPlayerLineUp === element.id_member?"selected":""}>
                                                                                    <td>{element.turn_no}</td>
                                                                                    {/* <td id={element.id_member} onClick={changeLineUpPlayerGuest} className="player-option font-size-16 text-primary">{element.player}</td> */}
                                                                                    <td className="font-size-16">{element.player}</td>
                                                                                    <td>{element.base}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        :null
                                                                    }
                                                                </tbody>
                                                                {
                                                                    // isLoading?
                                                                    //     <Row>
                                                                    //         <Col className="text-align-center">
                                                                    //             <Spinner animation="border" role="status" /> Cargando información...
                                                                    //         </Col>
                                                                    //     </Row>
                                                                    // :
                                                                    // memberForChangeGuest.length?
                                                                    // <tfoot>
                                                                    //     <tr>
                                                                    //         <th>
                                                                    //             <FormSelectGroup 
                                                                    //                 id="change-line-up-player-guest" 
                                                                    //                 name="change-line-up-player-guest" 
                                                                    //                 label="Jugadores visitantes" 
                                                                    //                 defaultValue={memberPlayerLineUpChangeGuest}
                                                                    //                 options={memberForChangeGuest}
                                                                    //                 onChange={(e)=>setMemberPlayerLineUpChangeGuest(e.currentTarget.value)}
                                                                    //             />
                                                                    //         </th>
                                                                    //         <th>
                                                                    //             <label style={{color: "#fff"}}>-</label>
                                                                    //             {
                                                                    //                 isLoading?
                                                                    //                     <p><Spinner animation="border" role="status" /> Cargando información...</p>
                                                                    //                 :
                                                                    //                 <Button variant="secondary" onClick={changePlayerInLineUpGuest}>
                                                                    //                     Cambiar Jugador
                                                                    //                 </Button>
                                                                    //             }                                                            
                                                                    //         </th>
                                                                    //     </tr>
                                                                    // </tfoot>
                                                                    // :null
                                                                }
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </>
                                            :
                                            <>
                                            <FormSelectGroup 
                                                id="guest_team" 
                                                name="guest_team" 
                                                label="Equipo de invitado" 
                                                defaultValue={guestTeam}
                                                options={myTeamsLst}
                                                onChange={guestChargeTeam}
                                            />
                                            <Row>
                                                <Col>
                                                    <Table responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Turno</th>
                                                                <th>Jugador</th>
                                                                <th>Posición</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                guestMemberTeam.map((element,idx)=>{
                                                                    return (
                                                                        <tr key={`tr-key-${idx}`}>
                                                                            <td>
                                                                                <FormSelectGroup 
                                                                                    id={`guest-turn-option-${idx}`} 
                                                                                    name={`guest-turn-option-${idx}`}
                                                                                    extra={idx}
                                                                                    label="Turno" 
                                                                                    defaultValue={element.turn_no}
                                                                                    options={[
                                                                                        {
                                                                                            value: 0,
                                                                                            text: "Turno"
                                                                                        },
                                                                                        {
                                                                                            value: 1,
                                                                                            text: "1"
                                                                                        },
                                                                                        {
                                                                                            value: 2,
                                                                                            text: "2"
                                                                                        },
                                                                                        {
                                                                                            value: 3,
                                                                                            text: "3"
                                                                                        },
                                                                                        {
                                                                                            value: 4,
                                                                                            text: "4"
                                                                                        },
                                                                                        {
                                                                                            value: 5,
                                                                                            text: "5"
                                                                                        },
                                                                                        {
                                                                                            value: 6,
                                                                                            text: "6"
                                                                                        },
                                                                                        {
                                                                                            value: 7,
                                                                                            text: "7"
                                                                                        },
                                                                                        {
                                                                                            value: 8,
                                                                                            text: "8"
                                                                                        },
                                                                                        {
                                                                                            value: 9,
                                                                                            text: "9"
                                                                                        },
                                                                                        {
                                                                                            value: 10,
                                                                                            text: "10"
                                                                                        },
                                                                                        {
                                                                                            value: 11,
                                                                                            text: "11"
                                                                                        },
                                                                                        {
                                                                                            value: 12,
                                                                                            text: "12"
                                                                                        },
                                                                                        {
                                                                                            value: 13,
                                                                                            text: "13"
                                                                                        },
                                                                                        {
                                                                                            value: 14,
                                                                                            text: "14"
                                                                                        },
                                                                                        {
                                                                                            value: 15,
                                                                                            text: "15"
                                                                                        }
                                                                                    ]}
                                                                                    onChange={changeGuestTeamPlayerTurn}
                                                                                    showLabel={false}
                                                                                />
                                                                            </td>
                                                                            <td>{element.text}</td>
                                                                            <td>
                                                                                <FormSelectGroup 
                                                                                    id={`${idx}`} 
                                                                                    name={`guest-base-option-${idx}`}
                                                                                    label="Base" 
                                                                                    defaultValue={element.id_base}
                                                                                    options={baseLst}
                                                                                    onChange={changeGuestTeamPlayerBase}
                                                                                    showLabel={false}
                                                                                />                                                            
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>                                    
                                                    </Table>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button variant="secondary" onClick={saveGuestLineUp}>
                                                        Guardar Line Up
                                                    </Button>
                                                </Col>
                                            </Row>
                                            </>
                                            }
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                            
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{setLineUpModal(false)}}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            :null
            }
        </>
    );
}

export default Game;