import React from "react";
import PropTypes from "prop-types";
import { Button } from 'react-bootstrap';

const CustomButton = (props) => {
    const { btn, ref, text, className, onClick, style } = props
    return <Button ref={ref} variant={btn} className={className} onClick={onClick} style={style}>{text}</Button>
}

CustomButton.propTypes = {
    btn: PropTypes.string.isRequired,
    ref: PropTypes.string,
    text: PropTypes.any.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.any
}

export default CustomButton;