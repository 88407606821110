import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Modal, Row, Spinner, Stack, Table } from "react-bootstrap";
import AppHeader from "../../Organisms/AppHeader";
import { EnvConfig } from "../../../Lib/EnvConfig";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import FormInputGroup from "../../Molecules/FormInputGroup";
import FormSelectGroup from "../../Molecules/FormSelectGroup";
import CustomButton from "../../Atoms/CustomButton";
import PlayerMenu from "../../Organisms/PlayerMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faRemove } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const teamData = {
    id: 0,
    name: "",
    id_sport: 0,
    id_type_team: 0
};

const Team = () => {
    const [team,setTeam] = useState(teamData),
        [sportLst,setSportLst] = useState([]),
        [teamType,setTeamType] = useState([]),
        [myTeamsLst,setMyTeamsLst] = useState([]),
        [myMembersLst,setMyMembersLst] = useState([]),
        [isLoadingData,setIsLoadingData] = useState(false),
        profile = useSelector((state)=>state.profile.value.payload),
        [isLoading,setIsLoading] = useState(false),
        [teamModal,setTeamModal] = useState(false),
        [teamSelected,setTeamSelected] = useState(0),
        [memberSelected,setMemberSelected] = useState(0),
        [memberSelectedLst,setMemberSelectedLst] = useState([]),
        [teamLst,setTeamLst] = useState([]),
        [createForm,setCreateForm] = useState(false);

    const handleValues = (input) => {
        const key = input.target.id,
            value = input.target.value;
        setTeam({
            ...team,
            [key]: value
        })
    }
    const getTeams = async() => {
        try{
            const get = await axios.get(`${EnvConfig.host}v1/teams/by/parent/${profile.id}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data; 
            setTeamLst(data);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const generalInfo = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/generals`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            let list = [],
                teamLst = [],
                myTeams = [],
                myMembers = [],
                sports = data.sports,
                teams = data.teams,
                myTeamsResource = data.myTeams,
                myMembersResource = data.myPlayers;
                list.push({value: 0, text: "Tipo de deporte"})
                teamLst.push({value: 0, text: "Tipo de equipo"})
                myTeams.push({value: 0, text: "Mis Equipos"})
                myMembers.push({value: 0, text: "Mis Jugadores"})
            for(let counter = 0; counter < sports.length; counter++){
                list.push({
                    value: sports[counter].id,
                    text: sports[counter].description
                });
            }
            for(let counter = 0; counter < teams.length; counter++){
                teamLst.push({
                    value: teams[counter].id,
                    text: teams[counter].description
                });
            }
            for(let counter = 0; counter < myTeamsResource.length; counter++){
                myTeams.push({
                    value: myTeamsResource[counter].id,
                    text: myTeamsResource[counter].description
                });
            }
            for(let counter = 0; counter < myMembersResource.length; counter++){
                myMembers.push({
                    value: myMembersResource[counter].id,
                    text: myMembersResource[counter].description
                });
            }
            setTeamType(teamLst);
            setSportLst(list);
            setMyTeamsLst(myTeams);
            setMyMembersLst(myMembers);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    useEffect(()=>{
        if(profile!==undefined&&profile!==null&&Object.keys(profile).length>0){
            getTeams();
            generalInfo();
        }        
    // eslint-disable-next-line
    }, []);
    const createTeam = async() => {
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/team/save`, team, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: data.message
            });
            setTeam(teamData);
            setMyTeamsLst(data.myTeams);
            getTeams();
            setIsLoading(false);
            setCreateForm(false);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const addMember = () => {
        let msl = [...memberSelectedLst];
        for(let c=0;c<myMembersLst.length;c++)
        {
            for(let i=0;i<memberSelectedLst.length;i++){
                if(parseInt(memberSelectedLst[i].value)===parseInt(memberSelected)){
                    Swal.fire({
                        icon: "warning",
                        title: "Advertencia",
                        text: "Este jugador ya se encuentra seleccionado",
                        confirmButtonColor: '#f00'
                    });
                    return;
                }
            }
            if(parseInt(myMembersLst[c].value)===parseInt(memberSelected)){
                msl.push(myMembersLst[c]);
                setMemberSelectedLst(msl);
                break;
            }
        }
    }
    const asignMembers = async() => {
        if(teamSelected<1){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "Debe seleccionar un equipo",
                confirmButtonColor: '#f00'
            });
            return;
        }
        if(memberSelectedLst.length<1){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "Debe seleccionar al menos un jugador",
                confirmButtonColor: '#f00'
            });
            return;
        }
        let list = [];
        for(let c=0;c<memberSelectedLst.length;c++){
            let itemId = 0;
            if(memberSelectedLst[c]["id"]!==undefined&&memberSelectedLst[c]["id"]!==null&&parseInt(memberSelectedLst[c]["id"])>0){
                itemId = parseInt(memberSelectedLst[c]["id"]);
            }
            list.push({
                id: itemId,
                id_member: memberSelectedLst[c].value,
                id_team: teamSelected
            });
        }        
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/team/members/save`, list, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: data.message
            });
            setMemberSelectedLst([]);
            setTeamSelected(0);
            setMemberSelected(0);
            setIsLoading(false);
            setTeamModal(false);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const removePlayer = (element) => {
        let 
            idx = element.currentTarget.getAttribute("id"),
            list = [...memberSelectedLst];
        list.splice(idx, 1);
        setMemberSelectedLst(list);
    }
    const changePlayers = async(input)=>{
        const value = input.target.value;
        setTeamSelected(value);
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/team/players/${value}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
            data = await get.data;
            setMemberSelectedLst(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const updateTeam = async(element) => {
        const id = element.currentTarget.getAttribute("id");
        if(id){
            setIsLoadingData(true);
            try{
                const get = await axios.get(`${EnvConfig.host}v1/teams/${id}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                    data = await get.data; 
                setTeam({
                    id: data.id,
                    name: data.t_name,
                    id_sport: data.id_sport,
                    id_type_team: data.id_type_team
                });
                setIsLoadingData(false);
                setCreateForm(true);
            } catch(error){
                setIsLoadingData(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        }
    }
    const clearTeam = () => {
        setTeam(teamData);
    }
    const removeTeam = async() => {
        if(team.id){
            setIsLoadingData(true);
            try{
                const post = await axios.post(`${EnvConfig.host}v1/team/remove`, { id: team.id }, { headers: { Authorization: `Bearer ${profile.token}`}}),
                    data = await post.data;
                if(!data.status){
                    Swal.fire({
                        icon: "warning",
                        title: "Advertencia",
                        text: data.message,
                        confirmButtonColor: '#f00'
                    });
                    setIsLoading(false);
                    return;
                }
                Swal.fire({
                    icon: "success",
                    title: "Operación Exitosa",
                    text: data.message
                });
                setIsLoadingData(false);
                setCreateForm(false);
                setTeam(teamData);
                setMyTeamsLst(data.myTeams);
                getTeams();
            } catch(error){
                setIsLoadingData(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        }
    }
    return (
    <>
        <AppHeader />
        <Container className="eight-height vertical-over">
        {
            (profile===undefined||profile===null||Object.keys(profile).length<1)?
                <Row>
                    <Col>
                        <p>Hemos detectado que refrescaste la app de forma no debida, Por favor <NavLink to="/login">Presiona aqui!</NavLink></p>
                    </Col>
                </Row>
            :
            (profile.m_type!=='admin')?
                <Row>
                    <Col>
                        <p>No tienes permisos para visualizar estas informaciones... Contacta al administrador</p>
                    </Col>
                </Row>
            :
            <>
            {
                isLoadingData?
                    <Row>
                        <Col className="text-align-center">
                            <p><Spinner animation="border" role="status" /> Cargando información...</p>
                        </Col>
                    </Row> 
                :null
            }
            {
                teamLst.length?
                    <Row>
                        <Col className="text-align-center font-size-24 bold">Tus Equipos</Col>
                    </Row>
                :null
            }
            {
                teamLst.length&&!createForm?
                <>
                        {
                        teamLst.map((elm,idx)=>{
                            return (
                                <Row key={`team-op-${idx}`}>
                                    <Col>
                                        <Card id={elm.id} key={idx} className="mb-3 cursor" onClick={updateTeam}>
                                            <Card.Body>
                                                <Card.Subtitle className="text-align-right font-size-24 bold text-primary"><FontAwesomeIcon icon={faEdit} /></Card.Subtitle>
                                                <Card.Title>{elm.t_name}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted">Deporte. {elm.sport}</Card.Subtitle>
                                                <Card.Subtitle className="mb-2 text-muted">Tipo. {elm.type_team}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        })
                        }
                </>
                :null
            }
            {
                createForm?
                <Row>
                    <Col xs={12} lg={12}>
                        <Card className="text-center bg-white mb-3">
                            <Card.Header>{team.id>0?"Actualización de equipo":"Crear Nuevo Equipo"}</Card.Header>
                            <Card.Body>
                                <Card.Title className="bold text-capitalize">Equipo</Card.Title>
                                <Card.Text className="text-muted">
                                    Todos los campos son requeridos para la creación del equipo.
                                </Card.Text>
                                <FormInputGroup 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    label="Nombre" 
                                    placeholder="Ingrese el nombre del equipo"
                                    value={team.name}
                                    onChange={handleValues}
                                    />
                                <FormSelectGroup 
                                    id="id_sport" 
                                    name="id_sport" 
                                    label="Tipo de deporte" 
                                    defaultValue={team.id_sport}
                                    options={sportLst}
                                    onChange={handleValues}
                                    />
                                <FormSelectGroup 
                                    id="id_type_team" 
                                    name="id_type_team" 
                                    label="Tipo de equipo" 
                                    defaultValue={team.id_type_team}
                                    options={teamType}
                                    onChange={handleValues}
                                    />
                                <Stack gap={2} className="col-md-5 mx-auto">
                                    {
                                        isLoading?
                                            <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                                        :
                                        <>
                                            <CustomButton btn="primary" text={team.id>0?"Actualizar Equipo":"Crear Equipo"} onClick={createTeam} />
                                            {
                                                team.id>0?
                                                <>
                                                    <CustomButton btn="secondary" text="Limpiar Equipo" onClick={clearTeam} />
                                                    <CustomButton btn="danger" text="Eliminar Equipo" onClick={removeTeam} />
                                                </>
                                                :null
                                            }                                        
                                            <CustomButton btn="outline-secondary" text="Agregar Miembro A Equipo" onClick={()=>{
                                                setMemberSelectedLst([]);
                                                setTeamSelected(0);
                                                setMemberSelected(0);
                                                setTeamModal(true)}
                                            } />
                                        </>
                                    }                                
                                </Stack>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                : null
            }
            <Row>
                <Col>
                    <Stack gap={2} className="col-md-5 mx-auto">
                        {
                            !createForm?
                                <CustomButton btn="primary" text="Crear equipo" onClick={()=>setCreateForm(true)}  />
                            :<CustomButton 
                                btn="danger" 
                                text="Cancelar" 
                                onClick={()=>{
                                    setTeam(teamData);
                                    setCreateForm(false)
                                }}  />
                        }                            
                    </Stack>
                </Col>
            </Row>
            </>
        }
        </Container>
        <Container className="ten-height d-flex align-items-end" fluid>
            {/* Menu Footer */}
            <PlayerMenu menu="team" />
        </Container>

        <Modal show={teamModal} onHide={()=>{setTeamModal(false)}}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Jugador A Equipo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <FormSelectGroup 
                        id="id_team_selected" 
                        name="id_team_selected" 
                        label="Mis Equipos" 
                        defaultValue={teamSelected}
                        options={myTeamsLst}
                        onChange={changePlayers}
                    />
                    <Row>
                        <Col><hr /></Col>
                    </Row>
                    <FormSelectGroup 
                        id="id_member_selected" 
                        name="id_member_selected" 
                        label="Mis Jugadores" 
                        defaultValue={memberSelected}
                        options={myMembersLst}
                        onChange={(input)=>{
                            const value = input.target.value;
                            setMemberSelected(value);
                        }}
                    />
                    <Button variant="success" onClick={addMember}>
                        Agregar Jugador
                    </Button>
                    <Row>
                        <Col>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Jugador</th>
                                        <th>Remover</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        memberSelectedLst.map((element,idx)=>{
                                            return (
                                                <tr key={`tr-key-${idx}`}>
                                                    <td>{element.text}</td>
                                                    <td><FontAwesomeIcon id={idx} onClick={removePlayer} icon={faRemove} style={{color: '#f00',fontSize: '22px'}} /></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>

            {
                isLoading?
                    <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                :
                <Button variant="primary" onClick={asignMembers}>
                    Guardar Jugadores
                </Button>
            }

                <Button variant="danger" onClick={()=>{setTeamModal(false)}}>
                    Cerrar
                </Button>

            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Team;