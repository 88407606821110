import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { EnvConfig } from "../../../Lib/EnvConfig";
import Swal from "sweetalert2";
import AppHeader from "../../Organisms/AppHeader";
import { Button, Card, Col, Container, Modal, Row, Spinner, Table } from "react-bootstrap";
import PlayerMenu from "../../Organisms/PlayerMenu";
import CustomButton from "../../Atoms/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

const metricsData = {
    hits: [],
    out: [],
    neutral: []
}

const TeamPlayers = () => {
    const [isLoadingData,setIsLoadingData] = useState(false),
            [isLoading,setIsLoading] = useState(false),
          [players,setPlayers] = useState([]),
          [metricModal,setMetricModal] = useState(false),
          [metrics,setMetrics] = useState(metricsData),
          [baseMetrics,setBaseMetrics] = useState([]),
          [player,setPlayer] = useState([]),
          profile = useSelector((state)=>state.profile.value.payload),
          { idGame, idTeam, noInning } = useParams(),
          [team,setTeam] = useState(null),
          [runs,setRuns] = useState(0),
          [game,setGame] = useState(null),
          navigate = useNavigate()
        ;
    let initPost = false;//Consultar información
    const chargeGame = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/games/${idGame}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            setGame(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const chargeTeam = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/teams/${idTeam}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            setTeam(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const chargePlayers = async() => {
        setIsLoadingData(true);
        try{
            const /*get = await axios.get(`${EnvConfig.host}v1/team/game/members/${idGame}/${idTeam}`, { headers: { Authorization: `Bearer ${profile.token}`}}),*/
                get = await axios.get(`${EnvConfig.host}v2/team/game/members/${idGame}/${idTeam}/${noInning}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            setPlayers(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const getInnings = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/get/innings/${idTeam}/${idGame}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            if(data){
                setRuns(data.run_inning);
            }
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const generalInfo = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/generals`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;
            // setMetrics({
            //     hits: data.metricsHit,
            //     out: data.metricsOut
            // });
            setBaseMetrics(data.metricsBase);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    useEffect(()=>{
        if((profile!==undefined&&profile!==null&&Object.keys(profile).length>0)&&initPost===false){
            chargeGame();
            getInnings();
            chargeTeam();
            chargePlayers();
            generalInfo();
            // eslint-disable-next-line
            initPost = true;
        }        
    // eslint-disable-next-line
    },[]);
    // const openPlayer = (element) => {
    //     const id = element.currentTarget.getAttribute("id");
    //     if(id){
    //         let player = null;
    //         for(let counter=0;counter<players.length;counter++){
    //             if(parseInt(players[counter].id_player)===parseInt(id)){
    //                 player = players[counter];
    //                 break;
    //             }
    //         }
    //         if(player===null){
    //             Swal.fire({
    //                 icon: "warning",
    //                 title: "Advertencia",
    //                 text: "Jugador no encontrado, favor intentar nuevamente",
    //                 confirmButtonColor: '#f00'
    //             });
    //             return;
    //         }
    //         setPlayer(player);
    //         setMetricModal(true);
    //     } else {
    //         Swal.fire({
    //             icon: "warning",
    //             title: "Advertencia",
    //             text: "Jugador no encontrado, favor intentar nuevamente",
    //             confirmButtonColor: '#f00'
    //         });
    //     }
    // }
    const openPlayerMetrics = async (element) => {
        const id = element.currentTarget.getAttribute("id");
        if(id)
        {
            let player = null;
            for(let counter=0;counter<players.length;counter++){
                if(parseInt(players[counter].id_player)===parseInt(id)){
                    player = players[counter];
                    break;
                }
            }
            if(player===null){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: "Jugador no encontrado, favor intentar nuevamente",
                    confirmButtonColor: '#f00'
                });
                return;
            }
            for(let i=0;i<baseMetrics.length;i++)
            {
                const obj = baseMetrics[i];                
                if ( parseInt(obj.id) === parseInt(player.id_base) ) 
                {
                    setMetrics({
                        hits: obj.metrics.hit,
                        out: obj.metrics.out,
                        neutral: obj.metrics.neutral
                    });
                    break;
                }
            }
            setPlayer(player);
            setMetricModal(true);
        } 
        else 
        {
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "Jugador no encontrado, favor intentar nuevamente",
                confirmButtonColor: '#f00'
            });
        }
    }
    const saveMetrics = async (element) => {
        const idMetric = element.currentTarget.getAttribute("id");
        if(idMetric)
        {
            setIsLoading(true);
            try{
                const post = await axios.post(`${EnvConfig.host}v1/season/team/metrics`, {
                    id_team: idTeam,
                    id_game: idGame,
                    id_member: player.id_player,
                    id_metric: idMetric,
                    no_inning: noInning,
                    id_season: 0,
                    quantity: 1
                }, { headers: { Authorization: `Bearer ${profile.token}`}}),
                    data = await post.data;    
                if(!data.status){
                    Swal.fire({
                        icon: "warning",
                        title: "Advertencia",
                        text: data.message,
                        confirmButtonColor: '#f00'
                    });
                    setIsLoading(false);
                    return;
                }
                // Swal.fire({
                //     icon: "success",
                //     title: "Operación Exitosa",
                //     text: data.message
                // });
                setPlayers(data.players);
                setMetricModal(false);
                setIsLoading(false);
            } catch(error){
                setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        }        
    }
    const removeMetrics = async (element) => {
        const idMetric = element.currentTarget.getAttribute("id");
        if(idMetric)
        {
            setIsLoading(true);
            try{
                const post = await axios.post(`${EnvConfig.host}v2/remove/season/team/metrics`, {
                    id: idMetric,
                    id_team: idTeam,
                    id_game: idGame,
                    id_member: player.id_player,
                    id_metric: 0,
                    no_inning: noInning,
                    id_season: 0
                }, { headers: { Authorization: `Bearer ${profile.token}`}}),
                    data = await post.data;    
                if(!data.status){
                    Swal.fire({
                        icon: "warning",
                        title: "Advertencia",
                        text: data.message,
                        confirmButtonColor: '#f00'
                    });
                    setIsLoading(false);
                    return;
                }
                setPlayers(data.players);
                setMetricModal(false);
                setIsLoading(false);
            } catch(error){
                setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error Interno",
                    text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                    confirmButtonColor: '#f00'
                });
            }
        }
    }
    const increaseRuns = async() => {
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/increase/runs`, {
                id_team: idTeam,
                id_game: idGame
            }, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            // Swal.fire({
            //     icon: "success",
            //     title: "Operación Exitosa",
            //     text: data.message
            // });
            setRuns(data.runs);
            setIsLoading(false);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const teamWins = async () => {
        setIsLoading(true);
        try{
            const post = await axios.post(
                `${EnvConfig.host}v1/set/the/winner`, 
                {
                    id_team: parseInt(idTeam),
                    id_game: parseInt(idGame)
                }, 
                { headers: { Authorization: `Bearer ${profile.token}`}}
            ),
            data = await post.data;    
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: data.message
            });
            setGame(data.game);
            setIsLoading(false);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    return (
        <>        
            <AppHeader />
            <Container className="eight-height vertical-over">                
            {
                team===null?
                    <Row>
                        <Col>
                            <p>Cargando datos de equipo...</p>
                        </Col>
                    </Row>
                :
                game!==undefined&&game!==null&&game.completed==='t'?
                <Row>
                    <Col>
                        <p>El juego ha sido completado, <NavLink to="/game/reported">Regresar a los juegos</NavLink></p>
                    </Col>
                </Row>
                :
                (profile===undefined||profile===null||Object.keys(profile).length<1)?                    
                    <Row>
                        <Col>
                            <p>Hemos detectado que refrescaste la app de forma no debida, Por favor <NavLink to="/login">Presiona aqui!</NavLink></p>
                        </Col>
                    </Row>
                :
                <>
                {
                    isLoadingData?
                        <Row>
                            <Col className="text-align-center">
                                <p><Spinner animation="border" role="status" /> Cargando información...</p>
                            </Col>
                        </Row>                        
                    :null
                }
                {
                    game?
                    <>
                        <Row>
                            <Col className="bold mb-3" style={{fontSize: '24px'}}>
                            <FontAwesomeIcon icon={faCircleArrowLeft} className="cursor" onClick={()=>navigate(`/team/game/${idGame}`)} /> Juego: {game.description}
                            </Col>
                        </Row>                        
                    </>
                    :null
                }
                {
                    players.length?
                        <Row>
                            <Col className="bold mb-3" style={{fontSize: '24px'}}>
                                <hr style={{marginTop: '0'}} />
                                Line Up Equipo - <strong>{team.t_name}</strong>
                            </Col>
                        </Row>
                    :null
                }
                <Row>
                    <Col>
                    {
                        isLoading?
                            <Card className="mb-3 cursor bg-green text-white bold text-align-center">
                                <Card.Body>
                                    <Card.Title><Spinner animation="border" role="status" /> Cargando...</Card.Title>
                                </Card.Body>
                            </Card>
                        :
                            <Card className="mb-3 cursor bg-green text-white bold text-align-center" onClick={increaseRuns}>
                                <Card.Body>
                                    <Card.Title>+Carrera</Card.Title>
                                </Card.Body>
                            </Card>
                    }                        
                    </Col>
                    <Col className="font-size-46 text-align-center bold text-success" style={{textDecoration: 'underline'}}>{runs}</Col>
                </Row>
                {
                    team?
                    <Row>
                        <Col>
                            <Table responsive>
                                <tbody>
                                    {/* <tr>
                                        <td className="text-align-right font-size-24 bold">Equipo:</td>
                                        <td className="text-align-left font-size-24 bold">{team.t_name}</td>
                                    </tr> */}
                                    <tr>
                                        <td className="text-align-right">Inning:</td>
                                        <td className="text-align-left">{noInning}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-align-right">Deporte:</td>
                                        <td className="text-align-left">{team.sport}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-align-right">Tipo:</td>
                                        <td className="text-align-left">{team.type_team}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    :null
                }
                {
                    players.length?
                    players.map((elm,idx)=>{
                            return (
                                <Row key={`team-game-${idx}`}>
                                    <Col>
                                        <Card id={elm.id_player} key={idx} className="mb-3 cursor" onClick={openPlayerMetrics}>
                                            <Card.Body>
                                                <Card.Title>{elm.player} <strong className="font-size-24">{elm.base}</strong></Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted text-align-right font-size-24">AVG. {elm.avg_player}</Card.Subtitle>
                                                <Card.Subtitle className="mb-2 text-muted text-align-left">Hits. {elm.hits}</Card.Subtitle>
                                                <Card.Subtitle className="mb-2 text-muted text-align-left">Outs. {elm.outs}</Card.Subtitle>
                                                <Card.Subtitle className="mb-2 text-muted text-align-left">BB. {elm.bb}</Card.Subtitle>
                                                <hr />
                                                {
                                                    elm.metrics?
                                                        elm.metrics.map((element,index)=>{
                                                            return <Card.Subtitle key={`met-${index}`} className={`mb-2 text-align-left ${element.m_reference==='hit'?'text-success':element.m_reference==='out'?'text-danger':'text-secondary'}`}>{element.metrica}. {element.quantity}</Card.Subtitle>
                                                        })
                                                    :null
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        })
                    :null
                }

                <Row>
                    <Col>
                    {
                        isLoading?
                        <Card className="mb-3 cursor bg-blue text-white bold text-align-center">
                            <Card.Body>
                                <Card.Title><Spinner animation="border" role="status" /> Cargando...</Card.Title>
                            </Card.Body>
                        </Card>
                        :
                        <Card className="mb-3 cursor bg-green text-white bold text-align-center" onClick={teamWins}>
                            <Card.Body>
                                <Card.Title>EQUIPO GANADOR<br />{team.t_name}</Card.Title>
                            </Card.Body>
                        </Card>
                    }                                
                    </Col>
                </Row>

                </>
            }
            </Container>
            <Container className="ten-height d-flex align-items-end" fluid>
                {/* Menu Footer */}
                <PlayerMenu />
            </Container>
            {
                (profile!==undefined&&profile!==null&&Object.keys(profile).length>0)?                    
                    <Modal show={metricModal} onHide={()=>{setMetricModal(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title>-Que paso con <strong className="font-size-24">{player.player}</strong>?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                {
                                    metrics.hits!==undefined&&metrics.hits!==null&&metrics.hits.length?
                                        <Row>
                                            <Col className="font-size-24 text-align-center text-success mb-3">+Positivas</Col>
                                        </Row>
                                    :null
                                }                                
                                {
                                    isLoading?
                                        <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                                    :
                                    metrics.hits!==undefined&&metrics.hits!==null&&metrics.hits.length?
                                        metrics.hits.map((elm,idx)=>{
                                            return (<Row>
                                                <Col>
                                                    <Card id={elm.id} key={`hits-${idx}`} className="mb-3 cursor" onClick={saveMetrics}>
                                                        <Card.Body>
                                                            <Card.Title>{elm.description}</Card.Title>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>)
                                        })
                                    :null
                                }
                                {
                                    metrics.neutral!==undefined&&metrics.neutral!==null&&metrics.neutral.length?
                                        <Row>
                                            <Col className="font-size-24 text-align-center text-secondary mb-3">=Neutral</Col>
                                        </Row>
                                    :null
                                }                                
                                {
                                    isLoading?
                                        <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                                    :
                                    metrics.neutral!==undefined&&metrics.neutral!==null&&metrics.neutral.length?
                                        metrics.neutral.map((elm,idx)=>{
                                            return (<Row>
                                                <Col>
                                                    <Card id={elm.id} key={`neutral-${idx}`} className="mb-3 cursor" onClick={saveMetrics}>
                                                        <Card.Body>
                                                            <Card.Title>{elm.description}</Card.Title>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>)
                                        })
                                    :null
                                }
                                {
                                    metrics.out!==undefined&&metrics.out!==null&&metrics.out.length?
                                        <Row>
                                            <Col className="font-size-24 text-align-center text-danger mb-3">-Negativas</Col>
                                        </Row>
                                    :null
                                }                                
                                {
                                    isLoading?
                                        <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                                    :
                                    metrics.out!==undefined&&metrics.out!==null&&metrics.out.length?
                                        metrics.out.map((elm,idx)=>{
                                            return (<Row>
                                                <Col>
                                                    <Card id={elm.id} key={`out-${idx}`} className="mb-3 cursor" onClick={saveMetrics}>
                                                        <Card.Body>
                                                            <Card.Title>{elm.description}</Card.Title>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>)
                                        })
                                    :null
                                }

                                {
                                    player.inningMetrics!==undefined&&player.inningMetrics!==null&&player.inningMetrics.length?
                                        <Row>
                                            <Col className="font-size-24 text-align-center text-primary mb-3">Remover metricas inning #{noInning}</Col>
                                        </Row>
                                    :null
                                }

                                {
                                    isLoading?
                                        <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                                    :
                                    player.inningMetrics!==undefined&&player.inningMetrics!==null&&player.inningMetrics.length?
                                        player.inningMetrics.map((elm,idx)=>{
                                            return (<Row>
                                                <Col>
                                                    <Card id={elm.id} key={`inning-${idx}`} className="mb-3 cursor" onClick={removeMetrics}>
                                                        <Card.Body>
                                                            <Card.Title><span className="bold text-danger">x</span> {elm.metric} <span className="bold">{elm.quantity}</span></Card.Title>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>)
                                        })
                                    :null
                                }

                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{setMetricModal(false)}}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                :null
            }
        </>
    )
}

export default TeamPlayers;