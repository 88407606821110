import React, { useState, useEffect } from 'react';
import { ButtonGroup, Card, Col, Container, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import AppHeader from "../../Organisms/AppHeader";
import PlayerMenu from "../../Organisms/PlayerMenu";
import { EnvConfig } from "../../../Lib/EnvConfig";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormInputGroup from '../../Molecules/FormInputGroup';
import CustomButton from '../../Atoms/CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';

const LiveStats = () => {
    const [isLoadingData,setIsLoadingData] = useState(false),
        [playersLst,setPlayersLst] = useState([]),
        [allPlayers,setAllPlayers] = useState([]),
        [criteria,setCriteria] = useState(""),
        profile = useSelector((state)=>state.profile.value.payload),
        navigate = useNavigate();
    let initPost = false;
    const getMembersByTeams = async () => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/players/by/team/player/${profile.id}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            setPlayersLst(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const getLstMembers = async () => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v2/all/players`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            setAllPlayers(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    useEffect(()=>{
        if((profile!==undefined&&profile!==null&&Object.keys(profile).length>0&&!initPost)){
            getMembersByTeams();
            getLstMembers();
            // eslint-disable-next-line
            initPost = true;
        }
    // eslint-disable-next-line
    }, []);
    const goToStats = (element) => {
        const id  = element.currentTarget.getAttribute("id");
        navigate(`/player/live/stats/${id}`);
    }
    const goToStatsByGeneral = (element) => {
        const id  = element.currentTarget.getAttribute("id").split("-");
        navigate(`/player/live/stats/${id[1]}`);
    }
    const searchMemberByCriteria = async() => {
        if(!criteria){
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: "Debe digitar el criterio de busqueda",
                confirmButtonColor: '#f00'
            });
            return false;
        }
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/players/by/criteria/data/${criteria}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            setPlayersLst(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    return (
        <>
            <AppHeader />
            <Container className="eight-height vertical-over">                
                {
                    isLoadingData?
                        <p><Spinner animation="border" role="status" /> Cargando información...</p>
                    :null
                }
                <Tabs
                    defaultActiveKey="partners"
                    id="members-content"
                    className="mb-3"
                    >
                    <Tab eventKey="partners" title="Estadísticas en vivo">
                        <Row>
                            <Col xs={8} lg={8}>
                                <FormInputGroup 
                                    type="text" 
                                    id="criteria" 
                                    name="criteria" 
                                    label="Buscar un jugador " 
                                    placeholder="Código, correo, telefono"
                                    value={criteria}
                                    onChange={((e)=>setCriteria(e.target.value))}
                                />
                            </Col>
                            <Col xs={4} lg={4}>
                                <label className='form-label' style={{color: '#dedddd'}}>-</label><br />
                                <ButtonGroup aria-label="Basic example">
                                    <CustomButton btn="primary" text={<FontAwesomeIcon icon={faSearch} />} onClick={searchMemberByCriteria} />
                                    <CustomButton btn="secondary" text={<FontAwesomeIcon icon={faRefresh} />} onClick={()=>getMembersByTeams()} />
                                </ButtonGroup>
                            </Col>
                        </Row>
                        {
                            playersLst!==undefined&&playersLst!==null&&playersLst.length?
                                <Row>
                                    <Col className="bold mb-3" style={{fontSize: '24px'}}>
                                        <hr />
                                        Compañero de equipo
                                    </Col>
                                </Row>
                            :null
                        }
                        {
                            playersLst!==undefined&&playersLst!==null&&playersLst.length?
                                playersLst.map((elm,idx)=>{
                                    return (
                                        <Row key={`live-stats-${idx}`}>
                                            <Col>
                                            {
                                                <Card id={elm.id} key={idx} className="mb-3 cursor" onClick={goToStats}>
                                                    <Card.Body>
                                                        <Card.Subtitle className="text-align-right font-size-24 bold text-primary">AVG. {elm.avg_player}</Card.Subtitle>
                                                        <Card.Title>{elm.player}</Card.Title>
                                                        <Card.Subtitle className="mb-2 text-muted">Correo electrónico. {elm.email}</Card.Subtitle>
                                                        <Card.Subtitle className="mb-2 text-muted">Teléfono. {elm.phone}</Card.Subtitle>
                                                    </Card.Body>
                                                </Card>
                                            }                                        
                                            </Col>
                                        </Row>
                                    )
                                })
                            :null
                        }
                    </Tab>
                    <Tab eventKey="members" title="Jugadores">
                        {
                            allPlayers?
                                allPlayers.map((elm,idx)=>{
                                    return (
                                        <Row key={`live-stats-${idx}`}>
                                            <Col>
                                            {
                                                <Card id={`allplayer-${elm.id}`} key={idx} className="mb-3 cursor" onClick={goToStatsByGeneral}>
                                                    <Card.Body>
                                                        <Card.Subtitle className="text-align-right font-size-24 bold text-primary">AVG. {elm.avg_player}</Card.Subtitle>
                                                        <Card.Title>{elm.player}</Card.Title>
                                                        <Card.Subtitle className="mb-2 text-muted">Correo electrónico. {elm.email}</Card.Subtitle>
                                                        <Card.Subtitle className="mb-2 text-muted">Teléfono. {elm.phone}</Card.Subtitle>
                                                    </Card.Body>
                                                </Card>
                                            }                                        
                                            </Col>
                                        </Row>
                                    )
                                })
                            :null
                        }
                    </Tab>
                </Tabs>
            </Container>
            <Container className="ten-height d-flex align-items-end" fluid>
                {/* Menu Footer */}
                <PlayerMenu />
            </Container>
        </>
    )
}

export default LiveStats;