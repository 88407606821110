import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const Select = (props) => {
    const { id, name, className, options, onChange, defaultValue, extra } = props;
    return (
        <>
            <Form.Select id={id} extra={extra} name={name} className={className} onChange={onChange} value={defaultValue}>
                {
                    options.map((element,index)=>{
                        return <option key={`select-op-${index}`} value={element.value}>{element.text}</option>
                    })
                }
            </Form.Select>
        </>
    )
}

Select.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any
}

export default Select;