import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import AppHeader from "../../Organisms/AppHeader";
import PlayerMenu from "../../Organisms/PlayerMenu";
import { EnvConfig } from "../../../Lib/EnvConfig";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faRunning } from "@fortawesome/free-solid-svg-icons";

const GameReported = () => {
    const [isLoadingData,setIsLoadingData] = useState(false),
          [games,setGames] = useState([]),
          profile = useSelector((state)=>state.profile.value.payload),
          navigate = useNavigate()
        ;
    const chargeGames = async() => {
        setIsLoadingData(true);
        try{
            const get = await axios.get(`${EnvConfig.host}v1/usr/games/${profile.id}`, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await get.data;    
            setGames(data);
            setIsLoadingData(false);
        } catch(error){
            setIsLoadingData(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    useEffect(()=>{
        if((profile!==undefined&&profile!==null&&Object.keys(profile).length>0)){
            chargeGames();
            //generalInfo();
        }        
    // eslint-disable-next-line
    },[]);
    const goToTeam = (card) => {
        const id = card.currentTarget.getAttribute("id");
        if(id){
            navigate(`/team/game/${id}`);
        }
    }
    return (
        <>
            <AppHeader />
            <Container className="eight-height vertical-over">                
                {
                    isLoadingData?
                        <p><Spinner animation="border" role="status" /> Cargando información...</p>
                    :null
                }
                {
                    games?
                        <Row>
                            <Col className="bold mb-3" style={{fontSize: '24px'}}>
                                <hr />
                                Juegos en el día de hoy.
                            </Col>
                        </Row>
                    :null
                }
                {
                    games?
                        games.map((elm,idx)=>{
                            return (
                                <Row key={`game-reported-${idx}`}>
                                    <Col>
                                    {
                                        elm.completed==='t'?
                                        <Card id={elm.id} key={idx} className="mb-3 cursor">
                                            <Card.Body>
                                                <Card.Subtitle className="text-align-right font-size-24 bold text-success">COMPLETADO. <FontAwesomeIcon icon={faCheck} /></Card.Subtitle>
                                                <Card.Title>{elm.description}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted"><strong>Ganador.</strong>{elm.winner} | <strong>Carrera.</strong>{elm.winner_runs}</Card.Subtitle>
                                                <Card.Subtitle className="mb-2 text-muted"><strong>Perdedor.</strong>{elm.loser} | <strong>Carrera.</strong>{elm.loser_runs}</Card.Subtitle>                                            
                                            </Card.Body>
                                        </Card>
                                        :
                                        <Card id={elm.id} key={idx} className="mb-3 cursor" onClick={goToTeam}>
                                            <Card.Body>
                                                <Card.Subtitle className="text-align-right font-size-24 bold text-primary">EN PROGRESO. <FontAwesomeIcon icon={faRunning} /></Card.Subtitle>
                                                <Card.Title>{elm.description}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted">Cant. Innings. {elm.innings}</Card.Subtitle>
                                                <Card.Subtitle className="mb-2 text-primary bold">Innings. #{elm.current_inning}</Card.Subtitle>
                                                <Card.Subtitle className="font-size-12 bold text-success">{elm.vs}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                    }                                        
                                    </Col>
                                </Row>
                            )
                        })
                    :null
                }
            </Container>
            <Container className="ten-height d-flex align-items-end" fluid>
                {/* Menu Footer */}
                <PlayerMenu />
            </Container>
        </>
    )
}

export default GameReported;