import React, { useState } from "react";
import { Card, Col, Container, Row, Table, Spinner, Stack, Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../../Organisms/AppHeader";
import { faCopy, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import PlayerMenu from "../../Organisms/PlayerMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../../Atoms/CustomButton";
import { EnvConfig } from "../../../Lib/EnvConfig";
import axios from "axios";
import Swal from "sweetalert2";
import FormInputGroup from "../../Molecules/FormInputGroup";
import { reset } from "../../../Features/Profile/profileSlice";

const passwordData = {
    id: 0,
    rePassword: "",
    reConfirmPassword: ""
}

const mCodeData = {
    code: ""
}

const Profile = () => {
    const [passwordObj,setPasswordObj] = useState(passwordData),
        [mCodeDataObj,setMCodeDataObj] = useState(mCodeData),
        [isLoadingData,] = useState(false), 
        [isLoading,setIsLoading] = useState(false),         
        profile = useSelector((state)=>(state.profile.value!==null&&state.profile.value!==undefined&&Object.keys(state.profile.value).length>0)?state.profile.value.payload:null),
        navigate = useNavigate(),
        dispatch = useDispatch(),
        [toastOpen,setToastOpen] = useState(false),
        [parentAccounts,setParentAccounts] = useState((profile!==undefined&&profile!==null&&profile.parentAccounts)?profile.parentAccounts:[]);
    const handleValues = (input) => {
        const key = input.target.id,
            value = input.target.value;
        setPasswordObj({
            ...passwordObj,
            [key]: value
        })
    }
    const updatePassword = async () => {
        setIsLoading(true);
        try{

            const pass = {
                ...passwordObj,
                id: profile.id
            }

            const post = await axios.post(`${EnvConfig.host}v1/update/member/password`, pass, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data; 

            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                icon: "success",
                title: "Operación Exitosa",
                text: data.message
            });            
            setIsLoading(false);
            sessionStorage.removeItem('BTYAPP-KEY-TOKEN');
            navigate('/login');
            dispatch(reset());
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const goOutFromApp = async() => {
        
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/go/out/app`, {idUsr: profile.id}, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data; 

            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                setMCodeDataObj(mCodeData);
                return;
            }
            sessionStorage.removeItem('BTYAPP-KEY-TOKEN');
            navigate('/login');
            dispatch(reset());
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }


    }
    const copyCode = () => {
        const code = profile.id.toString().padStart(4, '0');
        navigator.clipboard.writeText(code).then(()=>{
            setToastOpen(true);
            setTimeout(()=>{setToastOpen(false)}, 2000);
        });
    }
    const handleMemberCode = (input) => {
        const value = input.target.value;
        setMCodeDataObj({
            code: value
        });
    }
    const joinToRed = async() => {
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/join/to/member/red`, mCodeDataObj, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data; 

            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                setMCodeDataObj(mCodeData);
                return;
            }
            // Swal.fire({
            //     icon: "success",
            //     title: "Operación Exitosa",
            //     text: data.message
            // });            
            setIsLoading(false);
            setMCodeDataObj(mCodeData);
            setParentAccounts(data.parentAccounts);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    const removeAccount = async(element) => {
        const id = element.currentTarget.getAttribute("id");
        setIsLoading(true);
        try{
            const post = await axios.post(`${EnvConfig.host}v1/member/account/remove`, {id: id}, { headers: { Authorization: `Bearer ${profile.token}`}}),
                data = await post.data;
            if(!data.status){
                Swal.fire({
                    icon: "warning",
                    title: "Advertencia",
                    text: data.message,
                    confirmButtonColor: '#f00'
                });
                setIsLoading(false);
                return;
            }
            setIsLoading(false);
            setParentAccounts(data.parentAccounts);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error Interno",
                text: "Algo ocurrió, favor intentar mas tarde. Si el problema persiste llama al administrador",
                confirmButtonColor: '#f00'
            });
        }
    }
    return (
        <>
            <AppHeader />
            <Container className="eight-height vertical-over">
                
                {
                    isLoadingData?
                        <p><Spinner animation="border" role="status" /> Cargando información...</p>
                    :null
                }
                {
                    (profile===undefined||profile===null||Object.keys(profile).length<1)?                    
                        <Row>
                            <Col>
                                Hemos detectado que refrescaste la app de forma no debida, Por favor <NavLink to="/login">Presiona aqui!</NavLink>
                            </Col>
                        </Row>                    
                    :
                    <>
                    <Row>
                        <Col className="text-align-center font-size-24 bold text-primary">
                            <FontAwesomeIcon icon={faUser} /> Mi Perfil
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white mb-3">
                                <Card.Header>Informaciones Generales</Card.Header>
                                <Card.Body>
                                    <Card.Title className="bold text-capitalize">{profile.m_name}</Card.Title>
                                    <Card.Text className="text-muted">
                                        Estas son las informaciones que tenemos relacionadas a tu perfil
                                    </Card.Text>
                                    <Table responsive borderless>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Jugador</strong><br />
                                                    {profile.m_name}
                                                </td>
                                                <td>
                                                    <strong>Cuenta</strong><br />
                                                    {profile.email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Teléfono</strong><br />
                                                    {profile.phone}
                                                </td>
                                                <td>
                                                    <strong>Genero</strong><br />
                                                    {profile.gender}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Edad</strong><br />
                                                    {profile.age}
                                                </td>
                                                <td>
                                                    <strong>Rol</strong><br />
                                                    {profile.rol}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Tipo de cuenta</strong><br />
                                                    {profile.m_type}
                                                </td>
                                                <td>
                                                    <strong>Creado por</strong><br />
                                                    {profile.created_by}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Creado En</strong><br />
                                                    {profile.created_at}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white mb-3">
                                <Card.Header>Unirme a una red</Card.Header>                                
                                <Card.Body> 
                                    <Card.Subtitle>Digita el código de un miembro y unete a su red!</Card.Subtitle>
                                    <FormInputGroup 
                                        type="number" 
                                        id="memberCode" 
                                        name="memberCode" 
                                        label="Código de miembro" 
                                        placeholder="0000"
                                        value={mCodeDataObj.code}
                                        onChange={handleMemberCode}
                                        />  
                                    <Stack gap={2} className="col-md-5 mx-auto mb-3">
                                        {
                                            isLoading?
                                                <CustomButton btn="outline-info" text={<Spinner animation="border" role="status" />} />
                                            :
                                            <CustomButton btn="info" text="Unirme a la red!" onClick={joinToRed} />
                                        }                                        
                                    </Stack>
                                    {
                                        parentAccounts.length?
                                            <Table responsive>
                                                <thead>
                                                    <tr><th className="text-align-center" colSpan={2}>Tú cuenta esta asociada a:</th></tr>
                                                    <tr>
                                                        <th>Cuenta</th>
                                                        <td>Remover</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        parentAccounts.map((elx,indx)=>{
                                                            return (
                                                                <tr key={`parent-account-${indx}`}>
                                                                    <td>{elx.member}</td>
                                                                    <td>
                                                                        {
                                                                            isLoading?
                                                                                <Spinner animation="border" role="status" />
                                                                            : <FontAwesomeIcon id={elx.id} icon={faTimes} className="bold text-danger font-size-18 cursor" onClick={removeAccount} />
                                                                        }                                                                        
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        :null
                                    }                                    
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white mb-3">
                                <Card.Header>Actualización de contraseña</Card.Header>
                                <Card.Body>
                                    <FormInputGroup 
                                        type="password" 
                                        id="rePassword" 
                                        name="rePassword" 
                                        label="Nueva Contraseña" 
                                        placeholder="Ingrese la nueva contraseña"
                                        value={passwordObj.rePassword}
                                        onChange={handleValues}
                                        />
                                    <FormInputGroup 
                                        type="password" 
                                        id="reConfirmPassword" 
                                        name="reConfirmPassword" 
                                        label="Confirmar Contraseña" 
                                        placeholder="Confirma la nueva contraseña"
                                        value={passwordObj.reConfirmPassword}
                                        onChange={handleValues}
                                        />
                                    <Stack gap={2} className="col-md-5 mx-auto">
                                        {
                                            isLoading?
                                                <CustomButton btn="outline-secondary" text={<Spinner animation="border" role="status" />} />
                                            :
                                            <>                                        
                                                <CustomButton btn="primary" text="Actualizar Contraseña" onClick={updatePassword} />
                                            </>
                                        }                                
                                    </Stack>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={12}>
                            <Card className="text-center bg-white mb-3">
                                <Card.Header>Asocia Jugadores a tu equipo</Card.Header>                                
                                <Card.Body> 
                                    <Card.Subtitle>Comparte este codigo con posibles jugadores y agregalos a tu equipo.</Card.Subtitle>
                                    <Row>
                                        <Col xs={8} lg={8} className="text-align-right font-size-46 bold text-primary">
                                            {profile.id.toString().padStart(4, '0')}
                                            <Toast show={toastOpen}>
                                                <Toast.Body>Copiado en el clipboard!</Toast.Body>
                                            </Toast>
                                        </Col>
                                        <Col xs={4} lg={4} style={{paddingTop: '10px'}}>
                                            <FontAwesomeIcon icon={faCopy} className="text-align-left font-size-46 bold text-primary cursor" onClick={copyCode} />                                            
                                        </Col>
                                    </Row>                                   
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={12}>
                            <hr />
                            <Stack gap={2} className="col-md-5 mx-auto">
                                <CustomButton btn="danger" text="Salir de aplicación" onClick={goOutFromApp} />
                            </Stack>
                        </Col>
                    </Row>
                    
                    </>
                }                
            </Container>
            <Container className="ten-height d-flex align-items-end" fluid>
                {/* Menu Footer */}
                <PlayerMenu menu="profile" />
            </Container>
        </>
    )
}

export default Profile;